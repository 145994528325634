import { useFormik } from 'formik';
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import * as Yup from 'yup'
import { useAuth } from '../../auth/core/Auth';
import { UserModel } from '../../auth/core/_models';
import { login } from '../../auth/core/_requests';
import { getPermissions, savePermissions, updatePermissionsStatus, updatePermissions } from './../../../services/UserService';
import { Alert } from 'react-bootstrap';
import clsx from 'clsx';

let styleObj = {
  fontSize: 14,
  color: "#4a54f1",
  paddingTop: "100px"
}

let buttonStyles = {
  fontSize: 14,
  color: "#4a54f1",
  margin: 5
}


const submitScheme = Yup.object().shape({
  displayName: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(20, 'Maximum 20 character')
    .required('displayName is required'),
  permissionType: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('permissionType is required'),
})

const initialValues = {
  displayName: '',
  permissionType: '',
}

function UserPermissions() {


  const [permissions, setPermissions] = useState<any>([])

  useEffect(() => {
    console.log("Call permissions List");

    getPermissionsList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSending, setIsSending] = useState(false)
  const [permissionForm, setPermissionForm] = useState(false);
  const sendStatusRequest = useCallback(async (id: any, status: any) => {
    // don't send again while we are sending
    if (isSending) return
    const postdata = {
      id: id,
      active: status
    }
    // update state
    setIsSending(true)
    // send the actual request
    await updatePermissionsStatus(postdata)
    // once the request is sent, update state again
    setIsSending(false)
  }, [isSending]) // update the callback if the state changes


  const [loading, setLoading] = useState(false)
  const [isPermissionUpdate, setPermissionUpdate] = useState(false)
  const [permissionId, setPermissionID] = useState(0)

  const formik = useFormik({
    initialValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      var perId = 0;
      try {
        if (isPermissionUpdate) {
          perId = permissionId
        }
        const postdata = {
          id: perId,
          permissionType: values.permissionType,
          displayName: values.displayName,
        }
        if (isPermissionUpdate) {
          const response = await updatePermissions(postdata)
        } else {
          const response = await savePermissions(postdata)
        }
        setSubmitting(false)
        setLoading(false)
        getPermissionsList()
        resetForm();
        setPermissionUpdate(false)
        setPermissionID(0)
        setPermissionForm(false)

      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        setStatus('The fail to submit')
        setSubmitting(false)
        setLoading(false)

      }
    },
  })

  const updateValues = (permission: any) => {
    showPermissionForm()
    formik.values.displayName = permission.displayName;
    formik.values.permissionType = permission.permissionType;
    setPermissionUpdate(true);
    setPermissionID(permission.id);

  }

  const getPermissionsList = async () => {
    const response = await getPermissions();
    setPermissions(response.data.content)
  }
  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status
    }
    const { data } = await updatePermissionsStatus(postData)
    getPermissionsList()

  }

  const showPermissionForm = () => {
    resetForm();
    setPermissionForm(true);
  }

  const resetForm = () => {
    formik.values.displayName = '';
    formik.values.permissionType = '';
  }

  var collapseAbleHandler = () => {
    // const element = document.getElementById("collapseButton")
    // element?.click()
    setPermissionForm(false)
  }

  var rows = permissions && permissions.length > 0 && permissions.map((row: any, index: any) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.id}</td>
      <td>{row.displayName}</td>
      <td>{row.permissionType}</td>
      <td className='text-center'><button className={ row.active ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'} onClick={async () => { await updateStatus(row.id, !row.active) }}>{ row.active ? 'Active' : 'Inactive' }</button></td>
      <td className='text-center'>
        <button className='btn btn-sm btn-primary ml-3' onClick={() => updateValues(row)}>Edit</button>
      </td>
    </tr>
  })

  return (
    <div className="dashboard-middle-content">
      <h3>Permissions</h3>
      <div className="col-md-12">
        <div className="white-bg">
          <div className="float-right">
            { !permissionForm && (<button className="btn btn-grey ButtonRaast BtnRaastRed" id="collapseButton" onClick={showPermissionForm}>Add Permissions</button>) }
          </div>
          {permissionForm && (<div data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" style={{ backgroundColor: 'white', padding: '20px' }}>

            <form
              onSubmit={formik.handleSubmit}
              noValidate
              id='login_form'
            >
              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : (
                <>
                </>
              )}
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div><label className="labelName">Display Name</label></div>
                  <input
                    className={
                      clsx('w-100 form-control',
                        { 'classes.dashboard-top-filter-input': true },
                        { 'is-invalid': formik.touched.displayName && formik.errors.displayName },
                        { 'is-valid': formik.touched.displayName && !formik.errors.displayName }
                      )
                    }
                    {...formik.getFieldProps('displayName')}
                    type="text"
                    id="displayName"
                    name="displayName"
                    autoComplete='off' />
                  {
                    formik.touched.displayName && formik.errors.displayName && (
                      <div className='invalid-feedback'> {formik.errors.displayName} </div>
                    )
                  }
                  {/* <input className="form-control RaastInput" type="text" placeholder="Name"/> */}
                </div>
                <div className="col-md-6 mb-3">
                  <div><label className="labelName">Permission Type</label></div>
                  <input
                    className={
                      clsx('w-100 form-control',
                        { 'is-invalid': formik.touched.permissionType && formik.errors.permissionType },
                        { 'is-valid': formik.touched.permissionType && !formik.errors.permissionType }
                      )
                    }
                    {...formik.getFieldProps('permissionType')}
                    type="text"
                    id="permissionType"
                    name="permissionType"
                    autoComplete='off' />
                  {
                    formik.touched.permissionType && formik.errors.permissionType && (
                      <div className='invalid-feedback'> {formik.errors.permissionType} </div>
                    )
                  }
                </div>
                <div className="clearfix"></div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="float-right mt-4">
                    <button
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="2000"
                      type='submit'
                      id='sign_in_submit'
                      className='btn btn-info'
                      disabled={formik.isSubmitting || !formik.isValid}
                    >
                      {!loading && <span className='indicator-label'>Save</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                    <button className='btn btn-danger ml-4' type='button' onClick={collapseAbleHandler}>Cancel</button>
                  </div>
                </div>
              </div>
            </form>
          </div>)}
        </div>
      </div>
      {!permissionForm && (
        <div style={{ margin: '20px' }}>
          <table>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Id</th>
                <th>Name</th>
                <th>Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )
}

export default UserPermissions
