import React, { useEffect, useMemo, useState } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { ICompany, ICompanyService } from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import moment from "moment";
import { ISettlementFilter } from "../../models/interfaces/ISettlementFilter";
import {
  getCompanies,
  getCompanyServices,
} from "../../../../services/PsidService";
import {
  getSettlements,
  storeSettlement,
} from "../../../../services/SettlementService";
import { ISettlementResponse } from "../../models/interfaces/ISettlementResponse";
import { Button, Modal } from "react-bootstrap";
import { ISettlementRequest } from "../../models/interfaces/ISettlementRequest";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { dateFormat } from "../../../../utils/Utility";
import SelectSearchDropDown from "../../reusableComponents/SelectSearchDropDown";

const settlementFormSchema = Yup.object().shape({
  fromDate: Yup.string().required("Transaction from date is required"),
  toDate: Yup.string().required("Transaction to date is required"),
  // company: Yup.string().required('Company is required'),
  serviceId: Yup.string().required("Company service is required"),
  settlementAmount: Yup.number().required("Settlement amount is required"),
});

function DailySettlment() {
  const updateHeaderContext = useUpdateHeaderContext();

  // Add Settlement
  const [show, setShow] = useState(false);
  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const [loading, setLoading] = useState(false);

  const [settlements, setSettlements] = useState<ISettlementResponse[]>([]);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const [companyServices, setCompanyServices] = useState<ICompanyService[]>([]);
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [filter, setFilter] = useState<ISettlementFilter>({
    fromDate: _newDate,
    toDate: _newDate,
    serviceId: "0",
  });

  const initialValues = {
    fromDate: _newDate,
    toDate: _newDate,
    // company: '',
    serviceId: "",
    settlementAmount: "",
  };

  const formik = useFormik({
    initialValues,
    validationSchema: settlementFormSchema,
    onSubmit: async (values, { setStatus, setSubmitting, resetForm }) => {
      setLoading(true);
      try {
        const { data } = await storeSettlement({
          fromDate: values.fromDate,
          toDate: values.toDate,
          serviceId: values.serviceId,
          settlementAmount: Number(values.settlementAmount),
        });
        setShow(false);
        setLoading(false);
        resetForm();
        getSettlementData();
      } catch (error) {
        const err: any = error;
        console.error(err.response);
        setStatus(err.response.data.message);
        setSubmitting(false);
        setLoading(false);
        // resetForm();
      }
    },
  });

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Daily Settlements" });
    getCompanyList();
    // getCompanyServiceListForm();
    // getSettlementData();
  }, []);

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const getCompanyServiceList = async (companyId: number) => {
    const response = await getCompanyServices(companyId);
    setCompanyServices(response);
  };

  const onChangeFromDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, fromDate: e.target.value }));
  };

  const onChangeToDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, toDate: e.target.value }));
  };

  const companyChangeHandler = (e: any) => {
    if (e.target.value) {
      getCompanyServiceList(e.target.value);
    } else {
      setCompanyServices([]);
    }
    setFilter((filter: any) => ({ ...filter, serviceId: "0" }));
  };

  const serviceChangeHandler = (e: any) => {
    let serviceId = "0";
    if (e.target.value) {
      serviceId = e.target.value;
    } else {
      setCompanyServices([]);
    }
    setFilter((filter: any) => ({ ...filter, serviceId }));
  };

  // const companyChangeHandlerForm = (e: any) => {
  //     if(e.target.value) {
  //         getCompanyServiceListForm(e.target.value)
  //     } else {
  //         setCompanyServicesForm([])
  //     }
  // }

  const getSettlementData = async () => {
    if (filter.serviceId == "0") {
      alert("Please select company service first");
      return;
    }
    const response = await getSettlements(filter);
    if (response.status === 200) {
      const { content } = response.data;
      const settlementObj: ISettlementResponse[] = content;
      setSettlements(settlementObj);
      console.log(settlementObj);
    }
  };

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-9"></div>
          <div className="col-md-3">
            {/* <Button className='float-right' variant="primary" onClick={handleShow}>
                        Add Settlement
                    </Button> */}
            <button
              className="mt-2 dashboard-filter-buttonblue float-right"
              onClick={handleShow}
            >
              Add Settlement
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">From Settlement date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.fromDate || ""}
              onChange={onChangeFromDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">To Settlement date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.toDate || ""}
              onChange={onChangeToDate}
            />
          </div>
          {/* <div className="col-md-3">
                        <label className="filter-label">Company</label>
                        <select className='form-control' onChange={companyChangeHandler}>
                            <option value="">Select Company</option>
                            {
                                companies.map((c: ICompany) =>(<option key={c.getId()} value={c.getId()}>{c.getCompanyName()}</option>))
                            }
                        </select>
                    </div> */}
          <div className="col-md-3">
            <label className="filter-label">Company Service</label>
            {/* <select className='form-control' onChange={serviceChangeHandler}>
                            <option value="">Select Service</option>
                            {
                                companies.map((c: ICompany) =>(
                                        <optgroup key={c.getId()} label={c.getCompanyName()}>
                                            {
                                                c.getServices().map((cs:ICompanyService) =>(<option key={cs.getId()} value={cs.getId()}>{cs.getServiceName()}</option>))
                                            }
                                        </optgroup>
                                    )
                                )
                            }
                        </select> */}
            <SelectSearchDropDown
              setFilter={(companyServiceId: string) =>
                setFilter((filter: any) => ({
                  ...filter,
                  serviceId: companyServiceId,
                }))
              }
              companies={companies}
              //   setCurrentPage={setCurrentPage}
            />
          </div>
          <div className="col-md-3">
            <button
              className="mt-4 dashboard-filter-buttonblue float-right"
              onClick={getSettlementData}
            >
              Filter
            </button>
          </div>
        </div>
        {/* <div className="row">
                <div className="col-md-9"></div>
                    
                </div> */}
      </div>
      <div className="card mb-5 p-3">
        <h6>Settlements</h6>
        <div className="row">
          <div className="table-responsive p-2">
            <table>
              <thead>
                <tr>
                  <th>Company</th>
                  <th>Service</th>
                  <th>Transaction Date</th>
                  <th>Transaction Amount</th>
                  <th>Settlement Date</th>
                </tr>
              </thead>
              <tbody>
                {settlements && settlements.length > 0 ? (
                  settlements.map((s) => (
                    <tr>
                      <td>{s.companyName}</td>
                      <td>{s.serviceName}</td>
                      <td>
                        {dateFormat(s.fromDate)} - {dateFormat(s.toDate)}
                      </td>
                      <td>{s.settlementAmount}</td>
                      <td>{dateFormat(s.settlementDate)}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td className="text-center" colSpan={5}>
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
              <tbody>
                {settlements && settlements.length > 0 ? (
                  <tr>
                    <td className="text-right" colSpan={3}>
                      <b>Total:</b>
                    </td>
                    <td>
                      <b>
                        {settlements.reduce(
                          (a, s) => a + s.settlementAmount,
                          0
                        )}
                      </b>
                    </td>
                    <td></td>
                  </tr>
                ) : (
                  <></>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <form onSubmit={formik.handleSubmit} noValidate id="settlement_form">
          <Modal.Header>
            <Modal.Title>Add Settlement</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formik.status ? (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="col-sm-12 payzen-formgroup no-padding">
              <label className="payzen-label">Service</label>
              {/* <select
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.serviceId && formik.errors.serviceId,
                  },
                  {
                    "is-valid":
                      formik.touched.serviceId && !formik.errors.serviceId,
                  }
                )}
                {...formik.getFieldProps("serviceId")}
                id="serviceId"
                name="serviceId"
              >
                <option value="">Select Service</option>
                {companies.map((c: ICompany) => (
                  <optgroup key={c.getId()} label={c.getCompanyName()}>
                    {c.getServices().map((cs: ICompanyService) => (
                      <option key={cs.getId()} value={cs.getId()}>
                        {cs.getServiceName()}
                      </option>
                      
                    ))}
                  </optgroup>
                ))}
                {/* <optgroup label="Swedish Cars">
                                    <option value="volvo">Volvo</option>
                                    <option value="saab">Saab</option>
                                </optgroup>
                                {
                                    companyServicesForm.map((cs:ICompanyService) =>(<option key={cs.getId()} value={cs.getId()}>{cs.getServiceName()}</option>))
                                } 
              </select> */}

              <SelectSearchDropDown
                setFilter={(companyServiceId: string) =>
                  setFilter((filter: any) => ({ ...filter, companyServiceId }))
                }
                companies={companies}
                setCurrentPage={undefined}
              />

              {formik.touched.serviceId && formik.errors.serviceId && (
                <div className="invalid-feedback">
                  {" "}
                  {formik.errors.serviceId}{" "}
                </div>
              )}
            </div>
            <div className="col-sm-12 payzen-formgroup no-padding">
              <label className="payzen-label">Transaction From Date</label>
              <input
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.fromDate && formik.errors.fromDate,
                  },
                  {
                    "is-valid":
                      formik.touched.fromDate && !formik.errors.fromDate,
                  }
                )}
                {...formik.getFieldProps("fromDate")}
                type="date"
                id="fromDate"
                name="fromDate"
                autoComplete="off"
              />
              {formik.touched.fromDate && formik.errors.fromDate && (
                <div className="invalid-feedback">
                  {" "}
                  {formik.errors.fromDate}{" "}
                </div>
              )}
            </div>
            <div className="col-sm-12 payzen-formgroup no-padding">
              <label className="payzen-label">Transaction To Date</label>
              <input
                className={clsx(
                  "form-control",
                  {
                    "is-invalid": formik.touched.toDate && formik.errors.toDate,
                  },
                  { "is-valid": formik.touched.toDate && !formik.errors.toDate }
                )}
                {...formik.getFieldProps("toDate")}
                type="date"
                id="toDate"
                name="toDate"
                autoComplete="off"
              />
              {formik.touched.toDate && formik.errors.toDate && (
                <div className="invalid-feedback"> {formik.errors.toDate} </div>
              )}
            </div>
            <div className="col-sm-12 payzen-formgroup no-padding">
              <label className="payzen-label">Settlement Amount</label>
              <input
                className={clsx(
                  "form-control",
                  {
                    "is-invalid":
                      formik.touched.settlementAmount &&
                      formik.errors.settlementAmount,
                  },
                  {
                    "is-valid":
                      formik.touched.settlementAmount &&
                      !formik.errors.settlementAmount,
                  }
                )}
                {...formik.getFieldProps("settlementAmount")}
                type="text"
                id="settlementAmount"
                name="settlementAmount"
                autoComplete="off"
              />
              {formik.touched.settlementAmount &&
                formik.errors.settlementAmount && (
                  <div className="invalid-feedback">
                    {" "}
                    {formik.errors.settlementAmount}{" "}
                  </div>
                )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}

export default DailySettlment;
