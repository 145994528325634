import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { getCompanies, getCompanyServices } from '../../../../services/PsidService';
import { ICompany, ICompanyService } from '../../models/interfaces';
import { addCompanyService } from '../../../../services/AppService';

const initialServiceFormValues = {
  title: '',
  bankAccountNumber: '',
  serviceCode: '',
  tag: '',
  isAllowedPayment: false
}

const serviceSchema = Yup.object().shape({
  title: Yup.string().min(3, 'Minimum 3 characters').max(50, 'Maximum 50 characters').required('Service name is required'),
  bankAccountNumber: Yup.string().min(16, 'Minimum 16 characters').max(24, 'Maximum 24 characters').required('Bank account number is required'),
  serviceCode: Yup.string().required('Service code is required'),
  tag: Yup.string().required('Service tag is required'),
  isAllowedPayment: Yup.bool()
})

function CompanyService() {

  // Initialize States
  const [loading, setLoading] = useState(false)
  const [companies, setCompanies] = useState<ICompany[]>([])
  const [companyServices, setCompanyServices] = useState<ICompanyService[]>([])
  const [company, setCompany] = useState<any>('0')
  const [serviceForm, setServiceForm] = useState<Boolean>(false);
  const [initialFormValues, setInitialFormValues] = useState<any>(initialServiceFormValues);

  // Formik Initilize
  // const formik = useFormik({
  //   initialValues: initialFormValues,
  //   validationSchema: serviceSchema,
  //   onSubmit: async (values, { setStatus, setSubmitting }) => {
  //     setLoading(true)
  //     try {
  //       const { data } = await addCompanyService({
  //         title: values.title,
  //         bankAccountNumber: values.bankAccountNumber,
  //         serviceCode: values.serviceCode,
  //         tag: values.tag,
  //         isAllowedPayment: values.isAllowedPayment
  //       })
  //     } catch (error) {
  //       console.error(error)
  //       setStatus('The service details is incorrect')
  //       setSubmitting(false)
  //       setLoading(false)
  //     }
  //   },
  // })

  // Effects

  useEffect(() => {
    getCompanyList()
  }, []);

  // API Calls

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response)
  }

  const getCompanyServiceList = async () => {
    const response = await getCompanyServices(company);
    setCompanyServices(response)
  }

  // onChangeHandler
  const companyChangeHandler = (e: any) => {
    let companyId = '0';
    if (e.target.value) {
      companyId = e.target.value;

    } else {
      setCompanyServices([])
    }
    setCompany(companyId);
    // getCompanyServiceList()
  }

  const showServiceForm = () => {
    setServiceForm(true);
  }

  const handleSubmitService = async (data: any) => {
    if (company === '0') {
      alert('Please select company first')
      return
    }
    const payload = data
    payload.companyId = company;
    console.log(payload);
    try {
      const response = await addCompanyService(payload)
      console.log(response);
      setInitialFormValues(initialServiceFormValues);
      setServiceForm(false)
      setLoading(false)
      getCompanyServiceList()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Company Services</h6>
        <div className="row">
          <div className="col-md-6" v-if="getUserRole == 'ROLE_ADMIN' || getUserRole == 'PITB_FINANCE'">
            <label className="filter-label">Select Company</label>
            <select className='form-control' onChange={companyChangeHandler}>
              <option value="">All Companies</option>
              {
                companies.map((c: ICompany) => (<option key={c.getId()} value={c.getId()}>{c.getCompanyName()}</option>))
              }
            </select>
          </div>
          <div className="col-md-3">
            <button className="mt-8 dashboard-filter-buttonblue" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" onClick={getCompanyServiceList}>Fetch</button>

          </div>
          <div className="col-md-3">
            <button className="mt-8 dashboard-filter-buttongrey" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" onClick={showServiceForm}>Add Service</button>
          </div>
        </div>
        {serviceForm && (
          <Formik
            initialValues={initialFormValues}
            validationSchema={serviceSchema}
            onSubmit={handleSubmitService}  
          >
            {({ errors, touched, resetForm }) => (
              <Form className="mt-5">
                <h6>Add Services</h6>
                <div className="row" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                  <div className="col-md-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
                    <label className="payzen-label">Service Name</label>
                    <Field
                      className={
                        'form-control' +
                        (errors.title && touched.title ? ' is-invalid' : '')
                      }
                      type="text"
                      id="title"
                      name="title"
                      autoComplete='off' />
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-md-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
                    <label className="payzen-label">Bank Account Number</label>
                    <Field
                      className={
                        'form-control' +
                        (errors.bankAccountNumber && touched.bankAccountNumber ? ' is-invalid' : '')
                      }
                      type="text"
                      id="bankAccountNumber"
                      name="bankAccountNumber"
                      autoComplete='off' />
                    <ErrorMessage
                      name="bankAccountNumber"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-md-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
                    <label className="payzen-label">Service Code</label>
                    <Field
                      className={
                        'form-control' +
                        (errors.serviceCode && touched.serviceCode ? ' is-invalid' : '')
                      }
                      type="text"
                      id="serviceCode"
                      name="serviceCode"
                      autoComplete='off' />
                    <ErrorMessage
                      name="serviceCode"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-md-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">
                    <label className="payzen-label">Service Tag</label>
                    <Field
                      className={
                        'form-control' +
                        (errors.tag && touched.tag ? ' is-invalid' : '')
                      }
                      type="tag"
                      id="tag"
                      name="tag"
                      autoComplete='off' />
                    <ErrorMessage
                      name="tag"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-md-2" data-aos="fade-up" data-aos-anchor-placement="bottom-bottom" data-aos-duration="2000">

                    <Field
                      name="isAllowedPayment"
                      type="checkbox"
                      className={
                        'form-check-input' +
                        (errors.isAllowedPayment && touched.isAllowedPayment
                          ? ' is-invalid'
                          : '')
                      }
                    />
                    <label htmlFor="isAllowedPayment" className="form-check-label">
                      Allowed Payment
                    </label>
                    <ErrorMessage
                      name="acceptTerms"
                      component="div"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="col-md-2 no-padding">
                    <div className="row">
                      <div className="col-md-1">
                        <button
                          data-aos="flip-left"
                          data-aos-easing="ease-out-cubic"
                          data-aos-duration="2000"
                          type='submit'
                          id='sign_in_submit'
                          className='dashboard-filter-buttonblue'
                        // disabled={formik.isSubmitting || !formik.isValid}
                        >
                          {!loading && <span className='indicator-label'>Save</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                      </div>
                    </div>

                  </div>

                </div>

              </Form>
            )}
          </Formik>
        )}
      </div>
      {companyServices.length > 0 && (
        <div className="card mb-5 p-3">
          <h6>Company Service List</h6>
          <div className="row p-3">
            <table className='table'>
              <thead>
                <tr>
                  <th>ID #</th>
                  <th>Service Name</th>
                  <th>Company Name</th>
                  <th>Service Code</th>
                  <th>Bank Account Number</th>
                  <th>Payment Allowed</th>
                </tr>
              </thead>
              <tbody>
                {companyServices.map((cs: ICompanyService) => (
                  <tr key={cs.getId()}>
                    <td>{cs.getId()}</td>
                    <td>{cs.getServiceName()}</td>
                    <td>{cs.getCompanyName()}</td>
                    <td>{cs.getServiceCode()}</td>
                    <td>{cs.getBankAccountNumber()}</td>
                    <td>{cs.isPaymentAllowed()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default CompanyService