import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import axios from 'axios';

import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap";
// import { QueryClient, QueryClientProvider } from 'react-query';
// import { ReactQueryDevtools } from 'react-query/devtools'
import { AppRoutes } from './app/routing/AppRoutes';
import { AuthProvider } from './app/modules/auth/core/Auth';
import { setupAxios } from './app/services/InitializeAxios';

setupAxios(axios)

// const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    // <QueryClientProvider client={queryClient}>
        
    //   <ReactQueryDevtools initialIsOpen={false} />
    // </QueryClientProvider>
    <AuthProvider>
      <AppRoutes />
    </AuthProvider>
    
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
