import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import "../../assets/css/dashboard.css";
import { useAuth } from "../modules/auth/core/Auth";
import { HeaderContext, HeaderProvider } from "../contexts/HeaderContext";
import classes from "./style.module.css"
// import axios from 'axios';
// import { setupAxios } from '../modules/auth/core/AuthHelpers';

// setupAxios(axios)

export default function AppLayout() {
  const { auth, logout } = useAuth();
  const [menuIndexes, setMenuIndexes] = useState<number>();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  // const pageTitle = 'Page Title';
  const { pathname } = useLocation();
  const pageVariants = {
    initial: {
      opacity: 0,
    },
    in: {
      opacity: 1,
    },
    out: {
      opacity: 0,
    },
  };
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  const pageTransition = {
    type: "tween",
    ease: "linear",
    duration: 0.5,
  };

  const [menuLinks, setMenuLinks] = useState<any[]>([]);

  // Effects
  useEffect(() => {
    perpareMenus();
  }, []);

  useEffect(() => {
    if (pathname === `/app/dashboard`) setMenuIndexes(undefined);
  }, [pathname]);

  const perpareMenus = () => {
    const role: any = auth?.role;
    const rolePermissions: any = role.rolePermissions;
    const interfaces: any[] =
      rolePermissions &&
      rolePermissions.filter((rp: any) => rp.parentInterfaceId === null);
    let interfaceList: any[] = [];
    interfaces &&
      interfaces.forEach((i: any) => {
        const perm = i.permissions.find(
          (ip: any) => ip.permissionType === "VIEW"
        );
        if (perm) {
          let menu = i;
          const subInterfaces = rolePermissions.filter(
            (rp: any) => rp.parentInterfaceId === i.interfaceId
          );
          let subInterfacesList: any[] = [];
          subInterfaces.forEach((si: any) => {
            const sp = si.permissions.find(
              (ip: any) => ip.permissionType === "VIEW"
            );
            if (sp) {
              si.smactive = false;
              subInterfacesList.push(si);
            }
          });
          menu.haveSubMenu = subInterfacesList.length > 0;
          menu.collapse = false;
          menu.submenus = subInterfacesList;
          interfaceList.push(menu);
        }
      });
    interfaceList = interfaceList.sort((a, b) =>
      a.sequence < b.sequence ? -1 : 1
    );
    setMenuLinks(interfaceList);
  };

  const menuClick = (
    menuType: string,
    menuObj: any,
    index: number,
    pid = 0
  ) => {
    // console.log(menuType);

    if (menuType === "parent") {
      if (index === menuIndexes) setMenuIndexes(undefined);
      else setMenuIndexes(index);
    }
    const menu = menuObj;
    const menuLinkList = menuLinks;
    if (menuType === "parent") {
      menu.collapse = !menu.collapse;
      menuLinkList[index] = menu;
    } else {
      menu.active = !menu.active;
      const parentMenuIndex = menuLinkList.findIndex(
        (m) => m.interfaceId === pid
      );
      menuLinkList[parentMenuIndex].submenus[index] = menu;
    }
    // console.log(menuLinkList)
    setMenuLinks(menuLinkList);
    // const nAuth: any = auth;
    // nAuth.role.menuLinks = menuLinkList
    // setCurrentUser(nAuth);
  };

  const links = menuLinks && menuLinks.length && (
    <div className="list-group-flush">
      {menuLinks.map((m: any, mindex: number) => (
        <div key={m.interfaceId}>
          {!m.haveSubMenu ? (
            <Link
              className="list-group-item-action-main"
              to={"/app/" + m.interfaceLink}
            >
              {" "}
              {m.interfaceType}{" "}
            </Link>
          ) : (
            <>
              <div
                data-toggle="collapse"
                data-target={"#demo" + m.interfaceId}
                className="list-group-item-action-main cp"
                onClick={() => menuClick("parent", m, mindex)}
              >
                {" "}
                {m.interfaceType}{" "}
                {mindex === menuIndexes ? (
                  <i className="fa fa-angle-up" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                )}
              </div>
              {m.haveSubMenu && menuIndexes === mindex && (
                <div
                  id={"#demo" + m.interfaceId}
                  className="collapsed left-bar-main-collapse"
                >
                  {m.submenus.map((sm: any, smindex: number) => (
                    <Link
                      key={sm.interfaceId}
                      className="left-bar-submenu"
                      to={"/app/" + sm.interfaceLink}
                      onClick={() =>
                        menuClick("child", sm, smindex, m.interfaceId)
                      }
                      style={{
                        color:
                          pathname === `/app/${sm.interfaceLink}`
                            ? "#ffff"
                            : "",
                        backgroundColor:
                          pathname === `/app/${sm.interfaceLink}`
                            ? "#B1AEDD"
                            : "",
                      }}
                    >
                      {sm.interfaceType}
                    </Link>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
  return (
    <HeaderProvider>
      <div style={{ background: "#f4f4f4" }}>
        <div className={`wrapper ${isSidebarOpen ? 'toggled' : ''}`}>
          <div className="sidebar-wrapper">
            <div className="sidebar-heading clearfix">
              <div>
                <Link to="/app/dashboard">
                  <img
                    src="../../images/Logo.png"
                    alt="logo"
                    className="img-responsive"
                  />
                </Link>
              </div>
            </div>
            {links}
            {/* <div className="list-group-flush">
                        <Link className="list-group-item-action-main" to="/dashboard" v-if="showOnlyDashboard()"> Dashboard</Link>
                        <div data-toggle="collapse" data-target="#demo3" className="list-group-item-action-main cp"> Utilities <i data-aos="fade-right" data-aos-duration="2000" className="fa fa-angle-down" aria-hidden="true"></i></div>
                        <div id="demo3" className="collapse left-bar-main-collapse">
                            <Link className="left-bar-submenu" to="/app/generate-psids">Generate Psids</Link>
                            <Link className="left-bar-submenu" to="/app/psid-list">Generated Psid List</Link>
                            <Link className="left-bar-submenu" to="/app/registered-requests">Registered Requests</Link>
                            <Link className="left-bar-submenu" to="/app/compnay-services">Company Services</Link>
                        </div>
                        <div data-toggle="collapse" data-target="#report" className="list-group-item-action-main cp"> Report <i data-aos="fade-right" data-aos-duration="2000" className="fa fa-angle-down" aria-hidden="true"></i></div>
                        <div id="report" className="collapse left-bar-main-collapse">
                            <Link className="left-bar-submenu" to="/app/payment-transaction">Payment Transactions</Link>
                        </div>
                        <div data-toggle="collapse" data-target="#usermanagement" className="list-group-item-action-main cp"> User Management <i data-aos="fade-right" data-aos-duration="2000" className="fa fa-angle-down" aria-hidden="true"></i></div>
                        <div id="usermanagement" className="collapse left-bar-main-collapse">
                            <Link className="left-bar-submenu" to="/app/users">Users</Link>
                            <Link className="left-bar-submenu" to="/app/permissions">Permissions</Link>
                            <Link className="left-bar-submenu" to="/app/interfaces">Interfaces</Link>
                            <Link className="left-bar-submenu" to="/app/roles">Roles</Link>
                        </div>
                    </div> */}
          </div>
          <div className="page-content-wrapper">
            <HeaderContext.Consumer>
              {(value) => (
                <nav className="navbar navbar-expand-lg navbar-light nav-bg dashboard-fixed-top">
                  <div
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    className="trapezoid"
                    id="menu-toggle"
                    onClick={toggleSidebar} 
                  >
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </div>
                  <div
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    className="dashboard-header"
                  >
                    {value.pageTitle}{" "}
                  </div>

                  <div className="navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-responsive">
                      <li className="nav-item dropdown">
                        <div
                          data-aos="fade-right"
                          data-aos-duration="2000"
                          className="nav-link dropdown-toggle cp welcome-message"
                          id="navbarDropdown"
                          role="button"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Welcome {auth?.fullName}
                          <i className="fa fa-user" aria-hidden="true"></i>
                        </div>
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link
                            to="/app/profile/update-password"
                            className="list-group-item-action-main"
                          >
                            Change Password
                          </Link>
                          <span
                            onClick={logout}
                            className="list-group-item-action-main"
                          >
                            Logout
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </nav>
              )}
            </HeaderContext.Consumer>
            <motion.div
              key={pathname}
              initial="initial"
              animate="in"
              variants={pageVariants}
              transition={pageTransition}
              className={classes.parentNode}
            >
              <Outlet />
            </motion.div>
            <div className="pitb-footer">
              Powered By Punjab Information Technology Board @
              {new Date().getFullYear()}
            </div>
          </div>
        </div>
      </div>
    </HeaderProvider>
  );
}
