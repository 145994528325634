import { endPoints } from "./endpoints";
import instance from "./service";

export const paymentIntimationConfiguration = (
  payload: { serviceId: number, isActive: boolean }
): Promise<any> => {
  return instance.post(
    endPoints.PAYMENT_INTIMATION_CONFIGURATION_URL,
    payload
  );
};

export const paymentConfiguration = (
  payload: { serviceId: number, isActive: boolean }
): Promise<any> => {
  return instance.post(
    endPoints.PAYMENT_CONFIGURATION_URL,
    payload
  );
};
