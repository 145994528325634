import {ReactNode} from 'react'
// import {MenuComponent} from '../assets/ts/components'

type WithChildren = {
  children?: ReactNode
}

const dt =  {
    dbDateFormat: 'YYYY-MM-DD',  
}

// const reInitMenu = () => {
//   setTimeout(() => {
//     MenuComponent.reinitialization()
//   }, 500)
// }

export { type WithChildren, dt }