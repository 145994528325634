import { ICompanyRequest } from "./interfaces/ICompanyRequest";

export class CompanyRequest implements ICompanyRequest {
    id!: number;
    userName!: string;
    mobile!: string;
    email!: string;
    fullName!: string;
    cnic!: string;
    company!: string;

    constructor(values: Object = {}) {
        Object.assign(this, values)
    }

    getId():  number {
        return this.id
    }
    getUserName(): string {
        return this.userName
    }
    getMobile(): string {
        return this.mobile
    }
    getEmail(): string {
        return this.email
    };
    getFullName(): string {
        return this.fullName
    }
    getCnic(): string {
        return this.cnic
    }
    getCompany(): string {
        return this.company
    }
}