import { getAuth, removeAuth } from "../modules/auth/core/AuthHelpers"

export function setupAxios(axios: any) {
    axios.defaults.headers.Accept = 'application/json'
    axios.interceptors.request.use(
      (config: {headers: {Authorization: string}}) => {
        const auth = getAuth()
        if (auth && auth.token?.token) {
          config.headers.Authorization = `Bearer ${auth.token?.token}`
        }
  
        return config
      },
      (err: any) => Promise.reject(err)
    )
  
    axios.interceptors.response.use(
      ( response: any ) => {
        return response
      },
      function (error: any) {
        const originalRequest = error.config
        if ( error.response.status === 401 ) {
          console.log('Unauthorized');
          removeAuth()
          window.location.href = '/auth/login';
        }
    
        if (error.response.status === 401 && !originalRequest._retry) {
          // originalRequest._retry = true
          // const refreshToken = localStorageService.getRefreshToken()
          // return axios
          //   .post('/auth/token', {
          //     refresh_token: refreshToken
          //   })
          //   .then((res: { status: number; data: any }) => {
          //     if (res.status === 201) {
          //       localStorageService.setToken(res.data)
          //       axios.defaults.headers.common['Authorization'] =
          //         'Bearer ' + localStorageService.getAccessToken()
          //       return axios(originalRequest)
          //     }
          //   })
        }
        return Promise.reject(error)
      }
    )
    
  }