import { CompanyService } from "./CompanyService"
import { ICompany, ICompanyService } from "./interfaces"

export class Company implements ICompany {
    id!: number
    title!: string
    services!: CompanyService[]

    constructor(values: Object = {}) {
        Object.assign(this, values)
    }
    
    getId(): number {
        return this.id
    }
    getCompanyName(): string {
        return this.title
    }

    getServices(): ICompanyService[] {
        return this.services.map(s => new CompanyService(s))
    }

}