import { useFormik } from 'formik';
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import * as Yup from 'yup'

import { savePermissions, updatePermissionsStatus, updatePermissions, getInterfaces, updateInterfacesStatus, updateInterfaces, saveInterfaces, getPermissions, getRoles, updateRolesStatus, updateRoles, saveRoles, findRole } from './../../../services/UserService';
import clsx from 'clsx';
import { stat } from 'fs';
import { count, log } from 'console';

let styleObj = {
  fontSize: 14,
  color: "#4a54f1",
  paddingTop: "100px"
}

let addInterfaceButtonStyle = {
  marginTop: "36px"
}

let buttonStyles = {
  fontSize: 14,
  color: "#4a54f1",
  margin: 5
}

const permissionListStyle = {
  listStyle: 'none',
  display: 'inline-block',
  marginLeft: '10px'
}


const submitScheme = Yup.object().shape({
  roleType: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 20 symbols')
    .required('Role Type is required'),
  displayName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(20, 'Maximum 20 symbols')
    .required('Role Name is required')
})

const initialValues = {
  roleType: '',
  displayName: ''
}

const intialFormState = {
  roleId: 0,
  isRoleUpdate: false,
  role: {},
  selectedInterfaces: []
}

function UserRole() {

  // useMemo
  const roleTypes = useMemo(() => ['ROLE_ADMIN', 'ROLE_DEPARTMENT_ADMIN', 'PITB_FINANCE', 'COMPANY_ADMIN', 'ROLE_USER'], [])


  const [interfacesList, setInterfacesList] = useState<any>([])
  const [permissionsList, setPermissionList] = useState<any>([])
  const [roleList, setRoleList] = useState<any>([])
  const [selectedInterfaces, setSelectedInterfaces] = useState<any>([])
  const [selectedInterface, setSelectedInterface] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [isPermissionUpdate, setPermissionUpdate] = useState(false)
  const [editRole, setEditRole] = useState<any>({});
  const [permissionId, setPermissionID] = useState(0)
  const [showRoleForm, setShowRoleForm] = useState(false)
  const [formData, setFormData] = useState<any>(intialFormState)

  // UserEffect
  useEffect(() => {
    getInterfacesList()
  }, []);

  useEffect(() => {
    getPermissionsList()
  }, []);

  useEffect(() => {
    getRolesList()
  }, []);



  // useEffect(() => {
  //   prepareSelectedInterfaces();
  // }, [showRoleForm])

  const getInterfacesList = async () => {
    const response = await getInterfaces();
    setInterfacesList(response.data.content)
    // setTimeout(() => {
    //   prepareSelectedInterfaces();
    // }, 1000);
  }

  const getPermissionsList = async () => {
    const response = await getPermissions();
    var permiNew = prePareNewPermission(response.data.content)
    setPermissionList(permiNew)
  }

  const prePareNewPermission = (perList: any) => {

    var newList = []
    for (let i = 0; i < perList.length; i++) {
      if (perList[i].active) {
        newList.push({
          id: perList[i].id,
          permissionType: perList[i].permissionType,
          displayName: perList[i].displayName,
          active: perList[i].active,
          selected: false
        })
      }
    }
    return newList;
  }

  const getRolesList = async () => {
    const response = await getRoles();
    setRoleList(response.data.content)
  }

  const [isSending, setIsSending] = useState(false)
  const sendStatusRequest = useCallback(async (id: any, status: any) => {
    // don't send again while we are sending
    if (isSending) return
    const postdata = {
      id: id,
      active: status
    }
    // update state
    setIsSending(true)
    // send the actual request
    await updateRolesStatus(postdata)
    // once the request is sent, update state again
    setIsSending(false)
  }, [isSending]) // update the callback if the state changes




  const formik = useFormik({
    initialValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        const postdata = {
          id: formData.roleId,
          name: values.roleType,
          displayName: values.displayName,
          departmentServices: "1",
          companyId: 0,
          rolePermissions: getListOfRolePermissions()
        }
        if (formData.isRoleUpdate) {
          const response = await updateRoles(postdata)
        } else {
          const response = await saveRoles(postdata)
        }
        setSubmitting(false)
        setLoading(false)
        getInterfacesList()
        values.roleType = "";
        values.displayName = "";
        cancelForm();

      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        setStatus('The fail to submit')
        setSubmitting(false)
        setLoading(false)

      }
    },
  })


  // useEffect(() => {
  //   console.log('on edit role', editRole);
  //   if (editRole.id) {
  //     formik.values.displayName = editRole.displayName;
  //     formik.values.roleType = editRole.roleType;
  //     setPermissionID(editRole.id);
  //     prepareSelectedInterfaces();
  //   }
  // }, [showRoleForm])

  const openRoleForm = () => {
    formik.values.displayName = '';
    const formState: any = intialFormState
    formState.selectedInterfaces = prepareSelectedInterfaces()
    setFormData(formState);
    setTimeout(() => setShowRoleForm(true), 100)
  }

  const updateValues = (row: any) => {
    const formState: any = intialFormState
    formState.isRoleUpdate = true
    // const response = await findRole(row.id)
    // const role: any = response.data.content[0]
    const role: any = roleList.find((r: any) => r.id == row.id)
    formik.values.displayName = role.displayName;
    formik.values.roleType = role.roleType;
    formState.role = role
    formState.roleId = role.id
    formState.selectedInterfaces = prepareSelectedInterfaces(true, role)
    // console.log(formState)
    setFormData(formState)

    setTimeout(() => setShowRoleForm(true), 100);
  }

  const cancelForm = () => {
    setFormData(intialFormState)
    setShowRoleForm(false)
  }


  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status
    }
    const { data } = await updateRolesStatus(postData)
    getRolesList()
  }

  const getListOfRolePermissions = () => {
    var listOfPermissions = []

    for (var i = 0; i < selectedInterfaces.length; i++) {
      for (var j = 0; j < selectedInterfaces[i].permissions.length; j++) {
        var newPer = selectedInterfaces[i].permissions[j];
        if (newPer.selected) {
          listOfPermissions.push({ "interfaceId": selectedInterfaces[i].id, "permissionId": newPer.id })
        }
      }
    }
    return listOfPermissions;
  }

  const onInterfaceChangeHandler = (e: any) => {
    console.log("selected Value ", e);
    let menuText = 0;
    menuText = e.target.value
    console.log("selected Object", interfacesList[menuText]);
    setSelectedInterface(interfacesList[menuText]);
  }

  const prepareSelectedInterfaces = (isUpdate = false, editRole: any = {}) => {
    let interfaces: any[] = [];
    interfacesList.forEach((it: any) => {
      let permList: any[] = [];
      permissionsList.forEach((pl: any) => {
        let perm: any = {
          active: pl.active,
          displayName: pl.displayName,
          id: pl.id,
          permissionType: pl.permissionType
        }
        const val = isUpdate ? getInterfacePermissionStatus(it.id, pl.id, editRole) : false
        if (val) {
          perm.selected = val
        } else {
          perm.selected = false
        }
        permList.push(perm)
      })
      interfaces.push({
        id: it.id,
        interfaceName: it.interfaceType,
        selected: isUpdate ? getInterfaceSelectedStatus(it.id, editRole) : false,
        permissions: permList
      })
    });
    setSelectedInterfaces(interfaces)
    return interfaces;
  }

  const getInterfaceSelectedStatus = (interfaceId: number, editRole: any) => {
    const roleInterface = editRole.rolePermissions ? editRole.rolePermissions.find((rp: any) => rp.interfaceId == interfaceId) : null
    return roleInterface ? true : false
  }

  const getInterfacePermissionStatus = (interfaceId: number, permissionId: number, editRole: any) => {
    const roleInterface = editRole.rolePermissions ? editRole.rolePermissions.find((rp: any) => rp.interfaceId === interfaceId) : null
    if (roleInterface) {
      const roleInterfacePermission = roleInterface.permissions.find((rip: any) => rip.id === permissionId);
      return roleInterfacePermission ? true : false
    } else {
      return false
    }
  }



  const addNewInterfaces = () => {
    console.log("Selected Value Of Interface : ", selectedInterface)
    const newObj = {
      "id": selectedInterface.id,
      "interfaceName": selectedInterface.interfaceType,
      "permissions": Array.from(permissionsList)
    }
    console.log("saving object", newObj);
    setSelectedInterfaces((count: any) => [...count, newObj]);
    console.log("Select interfaces Array ", selectedInterfaces);
  }

  const updatePermissionCheckBox = (indexInt: any, newInterface: any, newPer: any, index: any) => {
    var newPerObj = newInterface.permissions[index];
    newPerObj.selected = !newPer.selected;
    // console.log("new permission", newPerObj);
    newInterface.permissions[index] = newPerObj;
    if (newPerObj.selected && !newInterface.selected) {
      newInterface.selected = true
    }
    // console.log("new Interface Obj ", newInterface);
    const currentSelectedInterfaces = selectedInterfaces;
    currentSelectedInterfaces[indexInt] = newInterface;
    // console.log("new Interface Obj ", currentSelectedInterfaces);
    setSelectedInterfaces(currentSelectedInterfaces);
    // console.log(currentSelectedInterfaces);
  }

  const updateInterfacesCheckbox = (index: number, interfaceObj: any) => {
    const interfaces = selectedInterfaces
    interfaceObj.selected = !interfaceObj.selected
    interfaces[index] = interfaceObj
    setSelectedInterfaces(interfaces);
    // console.log(interfaces);
  }



  var rows = roleList && roleList.length > 0 && roleList.map((row: any, index: any) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.id}</td>
      <td>{row.displayName}</td>
      <td>{row.roleType}</td>
      <td className='text-center'>
        <button className={row.active ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'} onClick={async () => { await updateStatus(row.id, !row.active) }}>{row.active ? 'Active' : 'Inactive'}</button>
      </td>
      <td className='text-center'>
        <button className='btn btn-sm btn-info ml-3' onClick={() => updateValues(row)}>Edit</button>
      </td>
    </tr>
  })

  var listItems = selectedInterfaces.map((newInterface: any, index: any) =>
    // console.log("this is new obje",newInterface.interfaceName)
    <li key={index}>{newInterface.interfaceName}
      <ul>
        {
          newInterface.permissions.map((perObj: any, perIndex: any) => (
            <li style={permissionListStyle} key={perObj.id}>
              <input type="checkbox" defaultChecked={perObj.selected} onClick={() => { updatePermissionCheckBox(index, newInterface, perObj, perIndex) }} />
              {perObj.displayName}</li>
          ))
        }
      </ul>
    </li>
  );


  // console.log("permission check",isPermissionUpdate, "intefaces check",selectedInterfaces)
  // setSelectedInterfaces(selectedInterfaces);


  return (
    <div className="dashboard-middle-content">
      <h3>Roles</h3>
      <div className="col-md-12">
        <div className="white-bg">
          <div className="float-right">
            {!showRoleForm && (<button className="btn btn-sm btn-info mb-3" type="submit" data-aos="fade-up" data-aos-easing="ease-out-cubic" onClick={openRoleForm}>Add Role</button>)}
          </div>
          {showRoleForm && (
            <div data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1500" style={{ backgroundColor: 'white', padding: '20px' }}>
              <form
                onSubmit={formik.handleSubmit}
                noValidate
                id='login_form'
              >
                {formik.status ? (
                  <div className='mb-lg-15 alert alert-danger'>
                    <div className='alert-text font-weight-bold'>{formik.status}</div>
                  </div>
                ) : (
                  <>
                  </>
                )}
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div><label className="labelName">Name</label></div>
                    <input
                      className={
                        clsx('w-100', 'form-control',
                          { 'is-invalid': formik.touched.displayName && formik.errors.displayName },
                          { 'is-valid': formik.touched.displayName && !formik.errors.displayName }
                        )
                      }
                      {...formik.getFieldProps('displayName')}
                      type="text"
                      id="displayName"
                      name="displayName"
                      autoComplete='off' />
                    {
                      formik.touched.displayName && formik.errors.displayName && (
                        <div className='invalid-feedback'> {formik.errors.displayName} </div>
                      )
                    }
                    {/* <input className="form-control RaastInput" type="text" placeholder="Name"/> */}
                  </div>
                  <div className="col-md-6 mb-3">
                    <div><label className="labelName">Role Type</label></div>
                    <input
                      className={
                        clsx('w-100', 'form-control',
                          { 'is-invalid': formik.touched.roleType && formik.errors.roleType },
                          { 'is-valid': formik.touched.roleType && !formik.errors.roleType }
                        )
                      }
                      {...formik.getFieldProps('roleType')}
                      type="text"
                      id="roleType"
                      name="roleType"
                      autoComplete='off' />
                    {
                      formik.touched.roleType && formik.errors.roleType && (
                        <div className='invalid-feedback'> {formik.errors.roleType} </div>
                      )
                    }

                    {/* <select className='form-control' id="roleType" name="roleType">
                      {
                        roleTypes.map((rt: any, index: any) => (<option key={index} value={index}>{rt}</option>))
                      }
                    </select> */}
                  </div>
                  <div className="col-md-12 mb-3">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Interfaces</th>
                          <th>Permissions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          formData.selectedInterfaces.map((interfaceObj: any, index: any) => (
                            <tr key={index}>
                              <td>
                                {/* <input type="checkbox" defaultChecked={interfaceObj.selected} onClick={() => { updateInterfacesCheckbox(index, interfaceObj) }} /> */}
                                {interfaceObj.interfaceName}
                              </td>
                              <td>{
                                interfaceObj.permissions.map((perObj: any, perIndex: any) => (
                                  <li style={permissionListStyle} key={perObj.id}>
                                    <input type="checkbox" defaultChecked={interfaceObj.selected && perObj.selected} onClick={() => { updatePermissionCheckBox(index, interfaceObj, perObj, perIndex) }} />
                                    {perObj.displayName}</li>
                                ))
                              }</td>
                            </tr>
                          )
                          )
                        }
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <div><label className="labelName">Menu Location</label></div>

                    <select className='form-control' id="interfaceName" name="interfaceName" onChange={onInterfaceChangeHandler}>
                      <option value="">Select Menu Location</option>
                      {
                        interfacesList.map((menuLocation: any, index: any) => (<option key={index} value={index}>{menuLocation.interfaceType}</option>))
                      }
                    </select>
                  </div>
                  <div className="col-md-6 mb-3"> <button className='btn-login-payzen' style={addInterfaceButtonStyle} type='button' onClick={() => addNewInterfaces()}>Add Interfaces</button>
                  </div> */}
                  <div className="col-md-6 mb-3">
                    {/* <ul >{listItems}</ul> */}
                  </div>
                  <div className="clearfix"></div>
                  {/* <div className="col-md-6"></div> */}
                  <div className="col-md-6">
                    <div className="float-right mt-4">
                      <button
                        data-aos="flip-left"
                        data-aos-easing="ease-out-cubic"
                        data-aos-duration="2000"
                        type='submit'
                        id='sign_in_submit'
                        className='btn btn-sm btn-info'
                        disabled={formik.isSubmitting || !formik.isValid}
                      >
                        {!loading && <span className='indicator-label'>Save</span>}
                        {loading && (
                          <span className='indicator-progress' style={{ display: 'block' }}>
                            Please wait...
                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                          </span>
                        )}
                      </button>
                      <button className='btn btn-sm btn-danger ml-4' type='button' onClick={cancelForm}>Cancel</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )
          }
        </div>
      </div>
      {!showRoleForm && (
        <div className='mt-5' data-aos="fade-down" data-aos-easing="ease-out-cubic" data-aos-duration="1500" style={{ margin: '20px' }}>
          <table>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Id</th>
                <th>Display Name</th>
                <th>Role Type</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      )}
    </div>
  )

}

export default UserRole
