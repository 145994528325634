import { RevertedTransactionFilter } from "../modules/app/models/interfaces/RevertTransaction";
import { endPoints } from "./endpoints";
import instance from "./service";

export const getRevertedTransactions = (
  payload: RevertedTransactionFilter
): Promise<any> => {
  return instance.post(
    endPoints.REJECTED_PAYMENT_CONFIRMATION_REPORT_URL,
    payload
  );
};

export const getRevertedTransactionsList = (
    payload: RevertedTransactionFilter
  ): Promise<any> => {
    return instance.post(
      endPoints.REVERTED_PAYMENT_LIST_URL,
      payload
    );
  };

  
export const revertTransaction = (
  psidId: number,
  consumerNumber: string
): Promise<any> => {
  const payload = { psidId, consumerNumber };
  return instance.post(
    endPoints.REVERT_PAYMENT_URL,
    payload
  );
};
