import axios from "axios";
import { ISettlementFilter } from "../modules/app/models/interfaces/ISettlementFilter"
import { ISettlementRequest } from "../modules/app/models/interfaces/ISettlementRequest";

const API_URL = process.env.REACT_APP_API_URL

export const DAILY_SATTLEMENT_URL = `${API_URL}/settlement/list`
export const STORE_SATTLEMENT_URL = `${API_URL}/settlement/store`

export const getSettlements = (payload: ISettlementFilter | undefined): Promise<any> => {
    return axios.post(DAILY_SATTLEMENT_URL, payload)
}

export function storeSettlement(payload: ISettlementRequest) {
    return axios.post(STORE_SATTLEMENT_URL, payload)
  }