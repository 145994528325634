import { endPoints } from './endpoints';
import { IntimationFilter } from "../modules/app/models/interfaces/IntimationFailureReport";
import instance from "./service";

export const getIntimationFailureReport = (
  payload: IntimationFilter
): Promise<any> => {
  return instance.post(endPoints.INTIMATION_FAILURE_REPORT_URL, payload);
};

export const getIntimationTriesDetail = (id: number): Promise<any> => {
  return instance.post(endPoints.INTIMATION_TRIES_DETAIL_URL, { id });
};

export const resetIntimations = (psids: number[]): Promise<any> => {
  return instance.post(endPoints.RESET_INTIMATIONS_URL, { psids });
};
