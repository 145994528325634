import React, { useState, useEffect } from 'react'
import { getRegisteredRequests, sendApprovalRequest } from '../../../../services/UserService'
import { ICompanyRequest } from '../../models/interfaces/ICompanyRequest'

function RegisteredRequest() {

  useEffect(() => {
    getRequests()
  }, [])
  

  // State
  const [ requests, setRequests ] = useState<any>([])

  const getRequests = async () => {
    const response = await getRegisteredRequests()
    setRequests(response)
  }

  const approveRequest = async (request:ICompanyRequest) => {
    const postData = { userId: request.getId()}
    const response = await sendApprovalRequest(postData)
    getRequests();
  }

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
      <h6>Company Registered Requests</h6>
      {requests.length > 0 && (
          <div className="row p-3">
            <table className='table'>
              <thead>
                <tr>
                  <th>ID #</th>
                  <th>Full Name</th>
                  <th>CNIC</th>
                  <th>Email</th>
                  <th>Company Name</th>
                  <th>Mobile No.</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {requests.map((cs: ICompanyRequest) => (
                  <tr key={cs.getId()}>
                    <td>{cs.getId()}</td>
                    <td>{cs.getFullName()}</td>
                    <td>{cs.getCnic()}</td>
                    <td>{cs.getEmail()}</td>
                    <td>{cs.getCompany()}</td>
                    <td>{cs.getMobile()}</td>
                    <td>
                    <button className="mt-8 dashboard-filter-buttonblue" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000" onClick={() => approveRequest(cs)}>Filter</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
      )}
      </div>
    </div>
  )
}

export default RegisteredRequest