import { ICompanyService } from "./interfaces";

export class CompanyService implements ICompanyService {
    id!: number;
    title!: string;
    bankAccountNumber!: string;
    companyName!: string;
    isAllowedPayment!: boolean;
    serviceCode!: string;


    // constructor(
    //     id: number,
    //     title: string,
    //     bankAccountNumber: string,
    //     companyName: string,
    //     isAllowedPayment: boolean,
    //     serviceCode: string){
    //         this.id = id
    //         this.title = title
    //         this.bankAccountNumber = bankAccountNumber
    //         this.companyName = companyName
    //         this.isAllowedPayment = isAllowedPayment
    //         this.serviceCode = serviceCode

    // }
    constructor(values: Object = {}) {
        Object.assign(this, values)
    }

    getId(): number {
        return this.id;
    }
    getServiceName(): string {
        return this.title;
    }
    getCompanyName(): string {
        return this.companyName;
    }
    getBankAccountNumber(): string {
        return this.bankAccountNumber;
    }
    getServiceCode(): string {
        return this.serviceCode;
    }
    isPaymentAllowed(): string {
        return this.isAllowedPayment ? 'Yes' : 'No';
    }
    
}