import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { motion } from 'framer-motion'
import '../../assets/css/auth.css'

export default function AuthLayout() {
    
    const { pathname } = useLocation();
    const pageVariants = {
            initial: {
                opacity: 0
            },
            in: {
                opacity: 1
            },
            out: {
                opacity: 0
            }
        };

    const pageTransition = {
        type: 'tween',
        ease: 'linear',
        duration: 0.5
    }; 
  return (
    <div className="payzen-login-main">
        <div className="col-sm-12">
            <div className="login-area-payzen">
                <div className="logo-login-payzen">
                    <Link to="/"><img src="../../images/Logo.png" alt="logo" className="img-responsive" /></Link>
                </div>
                
                <motion.div
                    key={pathname}
                    initial="initial"
                    animate="in"
                    variants={pageVariants}
                    transition={pageTransition}
                >
                    <Outlet />
                </motion.div>              
            </div>
        </div>
    </div>
  )
}
