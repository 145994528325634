import moment from "moment";
import React, { useMemo, useState, useEffect } from "react";
import {
  ICompany,
  ICompanyService,
  IPsidFilter,
} from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import { IPsidResponse } from "../../models/interfaces/IPsidResponse";
import {
  getCompanies,
  getCompanyServices,
  getPsidList,
} from "../../../../services/PsidService";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";
import ServicesSearchDropDown from "../../reusableComponents/ServicesSearchDropDown";
import { searchUtility } from "../../../../utils/searchUtility";
import {
  ILinkConfirmation,
  LinkConformationResponse,
} from "../../models/interfaces/ILinkConfirmation";
import { getLinkConfirmationDetails } from "../../../../services/LinkConfirmation";

function LinkConfirmation() {
  const updateHeaderContext = useUpdateHeaderContext();
  const [filter, setFilter] = useState<ILinkConfirmation>({
    consumerNumber: "",
    billType: "INVOICE",
  });

  const [linkDetails, setLinkDetails] = useState<LinkConformationResponse>();
  const billType: any = useMemo(() => ["INVOICE", "TOPUP"], []);

  const statusChangeHandler = (e: any) => {
    const billType = e.target.value;
    setFilter((filter: any) => ({ ...filter, billType }));
  };

  const getReportData = async () => {
    const response = await getLinkConfirmationDetails(filter);

    if (response.status === 200) {
      setLinkDetails(response.data.content?.[0]);
    }
  };

  const onChangeConsumerNumber = (e: any) => {
    const value = e.target.value.trim();
    if (value === "" || (/^\d+$/.test(value) && value.length <= 14)) {
      setFilter((prev) => ({ ...prev, consumerNumber: value }));
    }
  };

  useEffect(() => {
    updateHeaderContext({ pageTitle: "1Link Confirmation" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">Consumer Number</label>
            <input
              className="form-control"
              type="text"
              value={filter?.consumerNumber || ""}
              onChange={onChangeConsumerNumber}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">Bill Type</label>
            <select className="form-control" onChange={statusChangeHandler}>
              {billType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-3">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={getReportData}
            >
              Get Details
            </button>
          </div>
        </div>
      </div>
      {linkDetails && (
        <div className="col-md-6">
          <div className="card">
            <div className="card-header">1Link Confirmation details</div>
            <div className="card-body">
              {linkDetails &&
                Object.keys(linkDetails).map((key: string) => (
                  <div style={{ display: "flex" }}>
                    <p key={key} style={{ width: "53.5%" }}>
                      <strong>{key.replaceAll("_", " ")}</strong>
                    </p>
                    <p>{linkDetails[key as keyof typeof linkDetails]}</p>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default LinkConfirmation;
