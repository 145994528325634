import axios from "axios";
import { Company, CompanyService } from "../modules/app/models";
import { DashboardFilter } from "../modules/app/models/DashboardFilter";
import { ICompany, ICompanyService, IPsidFilter, IServiceSettlementFilter, ITransactionSummaryFilter } from "../modules/app/models/interfaces";
import { ITransactionSummaryDetailFilter } from "../modules/app/models/interfaces/ITransactionSummaryDetailFilter";
// import PsidList from '../utils/PsidList.json'

const API_URL = process.env.REACT_APP_API_URL

export const DASHBOARD_STATS_URL = `${API_URL}/reporting/getDashboardStats`
export const PAYMENT_TRASACTION_REPORT_URL = `${API_URL}/reporting/paymentTransactions`
export const SERVICE_SETTLEMENT_REPORT_URL = `${API_URL}/reporting/dateWiseServiceSettlement`
export const PSID_LIST_URL = `${API_URL}/reporting/psids`
export const COMPANY_URL = `${API_URL}/companies`
export const COMPANY_SERVICE_URL = `${API_URL}/getCompanyServices`
export const TRANSACTION_SUMMARY_URL = `${API_URL}/reporting/transactionsSummary`
export const TRANSACTION_SUMMARY_URL_ACCOUNT_WISE = `${API_URL}/reporting/accountWiseTransactionsSummary`
export const TRANSACTION_SUMMARY_DETAIL_URL = `${API_URL}/reporting/transactionsSummaryDetails`

export function getDashboardStats(payload: DashboardFilter | undefined) {
    return axios.post(DASHBOARD_STATS_URL, payload)
}

export const getPaymentTransactions = (payload: IPsidFilter | undefined): Promise<any> => {
    // console.log(payload);
    return axios.post(PAYMENT_TRASACTION_REPORT_URL, payload)
    // return Promise.resolve(PsidList)
}

export const getPsidList = (payload: IPsidFilter | undefined): Promise<any> => {
    return axios.post(PSID_LIST_URL, payload)
}

export function getCompanies(): Promise<ICompany[]> {
    return axios.get<ICompany[]>(COMPANY_URL)
    .then((res: any) => res.data.content.map((d: any) => new Company(d)))
    .catch(err => console.log(err)
)
}


export function getCompanyServices(companyId: number): Promise<ICompanyService[]> {
    return axios.post<ICompanyService[]>(COMPANY_SERVICE_URL, {companyId})
            .then((res: any) => res.data.content.map((d: any) => new CompanyService(d)))
            .catch(err => console.log(err)
        )
}

export function getCompaniesWithServices(): Promise<any> {
    return axios.get<any>(COMPANY_URL);
}

export const getServiceWiseSettlement = (payload: IServiceSettlementFilter | undefined): Promise<any> => {
    return axios.post(SERVICE_SETTLEMENT_REPORT_URL, payload)
}

export const getTransactionSummary = (payload: ITransactionSummaryFilter | undefined): Promise<any> => {
    return axios.post(TRANSACTION_SUMMARY_URL, payload)
}

export const getTransactionSummaryAccountWise = (payload: ITransactionSummaryFilter | undefined): Promise<any> => {
    return axios.post(TRANSACTION_SUMMARY_URL_ACCOUNT_WISE, payload)
}

export const getTransactionSummaryDetail = (payload: ITransactionSummaryDetailFilter | undefined): Promise<any> => {
    return axios.post(TRANSACTION_SUMMARY_DETAIL_URL, payload)
}