import React, { useMemo } from 'react'
import { useTable } from 'react-table'
import { COLUMNS } from '../../../../utils/table/Column'
import MOCK_DATA from '../../../../utils/table/MockData.json'
import '../../../../utils/table/table.css'

function BasicTable() {
    const columns: any = useMemo(() => COLUMNS, [])
    const data = useMemo(() => MOCK_DATA, [])

    const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } = useTable({
        columns,
        data
    })
    return (
        <div className="dashboard-middle-content">
            <div className="card mb-5 p-3">
                <h6>Filters</h6>
                <div className="row">
                    <div className="col-md-4">
                        <label className="filter-label">Start date</label>
                        <input className="dashboard-top-filter-input mb-2" type="date" />
                    </div>
                    <div className="col-md-4">
                        <label className="filter-label">End date</label>
                        <input className="dashboard-top-filter-input mb-2" type="date" />
                    </div>
                    <div className="col-md-4" v-if="getUserRole == 'ROLE_ADMIN' || getUserRole == 'PITB_FINANCE'">
                        <label className="filter-label">Company</label>
                        <select className='form-control'>
                            <option>RUDA</option>
                        </select>
                    </div>
                    <div className="col-md-4">
                        <label className="filter-label">Services</label>
                        <select className='form-control'>
                            <option>Chahar Bagh</option>
                        </select>
                    </div>
                    <div className="col-md-3">
                        <button className="mt-8 dashboard-filter-buttonblue" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">Filter</button>
                    
                    </div>
                    <div className="col-md-3">
                        <button className="mt-8 dashboard-filter-buttongrey" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">Download Excel</button>
                    </div>

                </div>
            </div>
            <div className="card p-3">
                <table {...getTableProps()}>
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map(row => {
                        prepareRow(row)
                        return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                            return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                            })}
                        </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    {footerGroups.map(footerGroup => (
                        <tr {...footerGroup.getFooterGroupProps()}>
                        {footerGroup.headers.map(column => (
                            <td {...column.getFooterProps()}>{column.render('Footer')}</td>
                        ))}
                        </tr>
                    ))}
                    </tfoot>
                </table>
            </div>
      </div>
    )
}

export default BasicTable