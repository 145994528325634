import {
    FC,
    useState,
    // useEffect,
    createContext,
    useContext,
    // useRef,
    Dispatch,
    SetStateAction,
  } from 'react'
import { UserModel } from "./_models"
import * as authHelper from './AuthHelpers'
import { WithChildren } from '../../../helpers/AppHelpers'

type AuthContextProps = {
    auth: UserModel | undefined
    saveAuth: (auth: UserModel | undefined) => void
    currentUser: UserModel | undefined
    setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
    logout: () => void
}

const initAuthContextPropsState = {
    auth: authHelper.getAuth(),
    saveAuth: () => {},
    currentUser: authHelper.getAuth(),
    setCurrentUser: () => {},
    logout: () => {},
  }

  const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

  const useAuth = () => {
    return useContext(AuthContext)
  }

  const AuthProvider: FC<WithChildren> = ({children}) => {
    const [auth, setAuth] = useState<UserModel | undefined>(authHelper.getAuth())
    const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
    const saveAuth = (auth: UserModel | undefined) => {
      setAuth(auth)
      if (auth) {
        authHelper.setAuth(auth)
      } else {
        authHelper.removeAuth()
      }
    }
  
    const logout = () => {
      saveAuth(undefined)
      setCurrentUser(undefined)
    }
  
    return (
      <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout}}>
        {children}
      </AuthContext.Provider>
    )
  }

  export { AuthProvider, useAuth }