import axios from "axios";
import { setupAxios } from "./InitializeAxios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

setupAxios(instance);

export default instance;
