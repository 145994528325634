import React from 'react';
import { Carousel } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../assets/css/landing.css';

function Landing() {
    const dt = new Date();
    
  return (
    <div>
      <header>
		<div className="navbar-wrapper">
        <nav className="navbar navbar-expand-md navbar-payzen">
            <Link to='/' className='navbar-brand'>
                <img src="images/Logo.png" alt="logo" className="img-responsive" />
            </Link>
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                
                <div className="animated-icon1"><span></span><span></span><span></span></div>
            </button>

            <div className="collapse navbar-collapse navbar-padding-left" id="navbarCollapse">
                <div className="navbar-nav navbar-nav-payzen">
                    <Link className="nav-item nav-link active" to="/dashboard">Home</Link>
                    <a href="#aboutsec" className="nav-item nav-link">About</a>
                    <a href="#vision" className="nav-item nav-link">Vision</a>
                    <a href="#payment-channels" className="nav-item nav-link">Payment channels</a>
                    <a href="#contact" className="nav-item nav-link">Contact</a>
                </div>
                <div className="navbar-nav ml-auto navbar-nav-left">
                    <Link to="/auth/login" className="signin-btn" data-aos="flip-left"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="2000"> Sign in </Link>
                </div>
            </div>
        </nav>
			</div>
        <div className="carousel-top text-left">
            <Carousel>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/payzen_landingbanner-04.png"
                    alt="First slide"
                    />
                    <Carousel.Caption>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                    <h1 className="banner-heading" data-aos="fade-up-right" data-aos-duration="2000">End To End <br />Payment With <br />Payzen</h1>
                                    <p className="banner-paragraph">Centralized Payment Protocol for P2G, P2P, B2G & B2B Payments for Commercial and Government Receipts</p>
                                    <a className="get-started-btn" href="/login" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">Get Started</a>
                                </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/payzen_landingbanner-04.png"
                    alt="Second slide"
                    />

                    <Carousel.Caption>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-8">
                            <h1 className="banner-heading">End To End <br/>Payment With <br/>Payzen</h1>
                            <p className="banner-paragraph">Centralized Payment Protocol for P2G, P2P, B2G & B2B Payments for Commercial and Government Receipts</p>
                            <a className="get-started-btn" href="/login">Get Started</a>
                        </div>
                        </div>
                    </div>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <img
                    className="d-block w-100"
                    src="images/payzen_landingbanner-04.png"
                    alt="Third slide"
                    />

                    <Carousel.Caption>
                        <div className="container">
                            <div className="row">
                            <div className="col-md-8">
                                <h1 className="banner-heading">End To End <br/>Payment With <br/>Payzen</h1>
                                <p className="banner-paragraph">Centralized Payment Protocol for P2G, P2P, B2G & B2B Payments for Commercial and Government Receipts</p>
                                <a className="get-started-btn" href="/login">Get Started</a>
                            </div>
                            </div>
                        </div>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </div>
	</header>
    <div className="parallax-container">
    <div className="landing-main-content">
		<div className="container">
			<h1 className="main-headings" data-aos="fade-up-left" data-aos-duration="2000">Drop Your Pen, Pay with Zen</h1>
			<p className="main-paragraph" data-aos="fade-up-left" data-aos-duration="2000">Go Cashless and paperless by Paying your Taxes/fees through Payzen</p>
            <div className="row mt-pay">
                <div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/Bio.png" className="img-responsive" alt='bio' />
                        <div className="name">Biometric Access</div>
                        <p>Use fingerprint or Face iD for Quick and easy access to your account information.</p>
                    </div>
                </div>
                <div className="col-md-4" data-aos="flip-left" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/security.png" className="img-responsive" alt='security' />
                        <div className="name">End to End Encryption</div>
                        <p>We have implemented best in-className fraud detection systems to keep your transaction safe</p>
                    </div>
                </div>
                <div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/accessible.png" className="img-responsive" alt='accessible' />
                        <div className="name">Accessible</div>
                        <p>Easily accessible form mobile and web platforms with payments under centralized biller.</p>
                    </div>
                </div>
            </div>
		</div>
		<section id="aboutsec" className="about-payzen" data-aos="fade-up"
     data-aos-duration="2000" style= {{background: "url(images/greycurve.png) center no-repeat", backgroundSize: "cover"}}>
            <div className="container">
				<div id="vision" className="row">
					<div className="col-lg-6 col-md-6" data-aos="flip-left" data-aos-duration="2000">
						<div className="about-img">
							<img src="images/About.png" className="img-responsive" alt='about' />
						</div>
					</div>
					<div className="col-lg-6 col-md-6 mrgn-btm-about">
						<h1 className="main-headings text-left">About Payzen</h1>
						<p className="about-text">
							PayZen came into inception in December 2020 with an idea to facilitate public by giving them a platform from which payments of Governmental & Non-Governmental Receipts  can be done with the ease of just a few clicks. It was built on the concept of bridging the the gap between Government and Public to increase the financial inclusion across Pakistan. Punjab IT Board has designed, developed and implemented the product with sheer dedication so that every citizen of Pakistan has access to formal digital financial services.
						</p>
					</div>
					<div className="col-lg-6 col-md-6">
						<h1 className="main-headings text-left">Vision</h1>
						<p className="about-text">
							In this technologically advanced ecosystem, Government of Punjab has envisioned to offer complete retreat from traditional and primitive payment processes that caused hassle and trouble. PayZen will simplify the task that indigenously involved physical visits to the collection offices across the province for Government & Commercial tax payments, and instigated bribery and corruption. Furthermore, PayZen will ensure transparency, uniformity in processing tasks, and a hassle-free experience. Commencing in Punjab, PayZen will be implemented in all the provinces soon.
						</p>
					</div>
					<div className="col-lg-6 col-md-6" data-aos="flip-right" data-aos-duration="2000">
						<div className="about-img vision-img">
							<img src="images/Vision.png" className="img-responsive" alt='vision' />
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="payment-channels" className="payment-channels">
			<div className="container">
			<h1 className="main-headings" data-aos="fade-up-left" data-aos-duration="2000">Payment Channels</h1>
			<p className="main-paragraph" data-aos="fade-up-left" data-aos-duration="2000">The application is a one-stop solution for tax payments which can be made from seven banking channels.</p>
            <div className="row mt-pay">
                <div className="col-md-3" data-aos="flip-up" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/mobile.png" className="img-responsive" alt='mobile' />
                        <div className="name">Mobile Banking</div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-down" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/Internet.png" className="img-responsive" alt='internet' />
                        <div className="name">Internet Banking</div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-up" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/atm.png" className="img-responsive" alt='atm' />
                        <div className="name">ATM</div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-down" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/OTC.png" className="img-responsive" alt='otc' />
                        <div className="name">Over the Counter (OTC)</div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-up" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/Security.png" className="img-responsive" alt='security' />
                        <div className="name">Branchless Banking</div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-down" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/debit.png" className="img-responsive" alt='debit' />
                        <div className="name">Debit/Credit Card Payment Gateway </div>
                    </div>
                </div>
                <div className="col-md-3" data-aos="flip-up" data-aos-duration="2000">
                    <div className="content_ways">
                        <img src="images/direct-debit.png" className="img-responsive" alt='direct debit' />
                        <div className="name">Direct Debit from Account</div>
                    </div>
                </div>
            </div>
		</div>
		</section>
		<section className="who-uses-payzen" data-aos="fade-up"
     data-aos-duration="2000" style={{background: "url(images/purplecurve.png) center no-repeat fixed", backgroundSize: "cover"}}>
			<div className="container">
				<h1 className="main-headings main-headings-white" data-aos="fade-up-left" data-aos-duration="2000">Who uses Payzen</h1>
				<p className="main-paragraph main-paragraph-white" data-aos="fade-up-left" data-aos-duration="2000">People use Payzen for the payment of Government and Commercial receipts for Public school fees, cantonment board receipts & Government institution fees.</p>
				<div className="row" v-if="false">
					<div className="col-md-4" data-aos="fade-right" data-aos-duration="2000">
						<div className="users-payzen">
							<div className="user-logo"><img src="images/user-logo.jpg" className="img-responsive" alt='logo' /></div>
							<p className="user-paragraph">People use Payzen for the payment of Government and Commercial receipts : from Public school fees, to cantonment board receipts, to Government istitution fees.</p>
							<div className="users-text">_Ahmed Khan Punjab Lahore</div>
						</div>
					</div>
					<div className="col-md-4" data-aos="flip-left" data-aos-duration="2000">
						<div className="users-payzen">
							<div className="user-logo"><img src="images/user-logo.jpg" className="img-responsive" alt='logo' /></div>
							<p className="user-paragraph">People use Payzen for the payment of Government and Commercial receipts : from Public school fees, to cantonment board receipts, to Government istitution fees.</p>
							<div className="users-text">_Ahmed Khan Punjab Lahore</div>
						</div>
					</div>
					<div className="col-md-4" data-aos="fade-left" data-aos-duration="2000">
						<div className="users-payzen">
							<div className="user-logo"><img src="images/user-logo.jpg" className="img-responsive" alt='logo' /></div>
							<p className="user-paragraph">People use Payzen for the payment of Government and Commercial receipts : from Public school fees, to cantonment board receipts, to Government istitution fees.</p>
							<div className="users-text">_Ahmed Khan Punjab Lahore</div>
						</div>
					</div>
				</div>
			</div>
		</section>
		<section id="contact" className="footer_upper" style={{background: "url(images/footerbg.jpg) center no-repeat fixed", backgroundSize: "cover"}}>
                    <div className="container">
                    <div className="row">
                            <div className="col-md-4">
                                
                            </div>
                        <div className="col-md-4 padding-left-form">
                            <div className="heading form-group">Follow Us on</div>
                            <ul className="list-unstyled social list-height">
                                <li className="social_icons">
                                    <img src="images/facebook1.png" alt='facebook' />
                                    <a href="https://www.facebook.com/payzenpitb/?ref=page_internal" target="blank">Facebook</a>
                                </li>
                                <li className="social_icons">
                                    <img src="images/twitter1.png" alt='twitter' /><a href="https://twitter.com/OfficialPayzen"  target="blank">Twitter</a>
                                </li>
                                <li className="social_icons">
                                    <img src="images/linkedin.png" alt='linkedin' /><a href="https://www.linkedin.com/company/86280035/admin/"  target="blank">LinkedIn</a>
                                </li>
                                <li className="social_icons">
                                    <img src="images/instagram.png" alt='instagram' /><a href="https://www.instagram.com/payzenofficial/"  target="blank">Instagram</a>
                                </li>
                                <li><a href="#aboutsec">About</a></li>
                                <li><a href="#vision">Vision</a></li>
                                <li><a href="#payment-channels">Payment Channels</a></li>
                            </ul>
                        </div>
                        <div className="col-md-4 contact-area">
                            <div className="heading form-group">
                                <img src="images/contact.png" alt='contact' /> Address.
                            </div>
                            <p>12th Floor Arfa Software Technology Park، 346-B Ferozepur Road, Lahore, Punjab 54000</p>
                            <div className="heading form-group">
                                <img src="images/fax.png" alt='fax' /> Telephone
                            </div>
                            <p>+92 - 42 - 99000000</p>
                            <div className="heading form-group">
                                <img src="images/email.png" alt='fax' /> Fax
                            </div>
                            <p>+92 - 42 - 99232123</p>
                            <div className="heading form-group">
                                <img src="images/email.png" alt='email' /> Email
                            </div>
                            <p>support@payzen.pk</p>
                        </div>
                    </div>
                    <div className="footer-logo-main clearfix">
                        <div className="float-left">
                            <Link to='/'><img src="images/Logo.png" alt="logo" className="img-responsive" /></Link>
                        </div>
                        <div className="float-right mt-3" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                            <Link to="/register" className="signin-btn">Sign up</Link>
                        </div>
                    </div>
                </div>
                </section>
                <footer className="footer text-center">
                    <div>&copy; { dt.getFullYear() } - Powered by Punjab Information Technology Board</div>
            </footer>
	</div>
</div>
    </div>
  );
}

export default Landing;