import moment from "moment";
import { useMemo, useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { dt } from "../../../../helpers/AppHelpers";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";

import { numberWithCommas } from "../../../../utils/Utility";
import { getRevertedTransactionsList } from "../../../../services/RevertTransaction";
import {
  RevertedTransactionFilter,
  RevertedTransactionResponse,
} from "../../models/interfaces/RevertTransaction";

function RevertedTransaction() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [loader, _] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const initialFilter: RevertedTransactionFilter = {
    startDate: _newDate,
    endDate: _newDate,
    pageIndex: currentPage - 1,
    pageSize: 10,
    consumerNumber: "",
  };
  const isFirstRender = useRef(true);
  const [filter, setFilter] =
    useState<RevertedTransactionFilter>(initialFilter);

  const getReportData = async (init: boolean = false) => {
    if (moment(filter.startDate).isAfter(filter.endDate) && !init) {
      Swal.fire({
        icon: "error",
        title: "Date Range Error",
        text: "From Date can not be greater than To Date",
      });
      return;
    }
    setPageData((prev: any) => ({ ...prev, isLoading: true }));
    const response = await getRevertedTransactionsList(
      init
        ? {
            ...initialFilter,
            startDate: `${initialFilter.startDate} 00:00:00`,
            endDate: `${initialFilter.endDate} 23:59:59`,
          }
        : {
            ...filter,
            pageSize: Number(filter.pageSize),
            startDate: `${filter.startDate} 00:00:00`,
            endDate: `${filter.endDate} 23:59:59`,
          }
    );
    if (response.status === 200) {
      const { content, message } = response.data;
      const psidObj: RevertedTransactionResponse[] = content;
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj,
        totalPages: 10,
        totalRows: Number(message),
      }));
    }
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  function onSearchChange(value: any) {
    setFilter((filter: any) => ({
      ...filter,
      consumerNumber: value,
      pageIndex: 0,
    }));
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  let columns: any = useMemo(() => {
    let columns = [
      {
        Header: "Consumer Number",
        accessor: "consumerNumber",
      },
      {
        Header: "Transaction Auth ID",
        accessor: "transactionAuthId",
      },
      {
        Header: "Bank",
        accessor: "bank",
      },
      {
        Header: "Transaction Amount",
        accessor: "transactionAmount",
      },
      {
        Header: "Transaction Date Time",
        accessor: (row: any) => `${row.transactionDate} ${row.transactionTime}`,
        id: "transactionDateTime",
      },
      {
        Header: "Reverted Date",
        accessor: "revertedDate",
      },
      {
        Header: "Reverted By",
        accessor: "revertedBy",
      },
    ];

    return columns;
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      getReportData();
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Reverted PSID Report" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">Consumer Number</label>
            <input
              className="form-control"
              type="text"
              value={filter?.consumerNumber || ""}
              onChange={(e) => {
                const value = e.target.value.trim();
                if (
                  value === "" ||
                  (/^\d+$/.test(value) && value.length <= 14)
                ) {
                  onSearchChange(value);
                }
              }}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">From Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">To Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
            />
          </div>

          <div className="col-md-3" />

          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {pageData?.rowData && (
        <div className="card p-3">
          <AppTable
            columns={columns}
            data={pageData.rowData.map((row: any) => ({
              ...row,
              transactionAmount: numberWithCommas(row.transactionAmount),
            }))}
            isLoading={pageData.isLoading || loader}
          />
          <Pagination
            totalRows={pageData.totalRows}
            pageChangeHandler={setPageIndex}
            rowsPerPage={filter.pageSize}
            currentPage={currentPage}
            pageItemChangeHandler={onLoadPageItems}
          />
        </div>
      )}
    </div>
  );
}

export default RevertedTransaction;
