import moment from "moment";
import { useMemo, useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { ICompany, ICompanyService } from "../../models/interfaces";
import { dt } from "../../../../helpers/AppHelpers";
import { getCompanies } from "../../../../services/PsidService";
import AppTable from "../table";
import Pagination from "../table/pagination";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";

import {
  IntimationFailureResponse,
  IntimationFilter,
  IntimationTriesDetailResponse,
} from "../../models/interfaces/IntimationFailureReport";
import {
  getIntimationFailureReport,
  getIntimationTriesDetail,
  resetIntimations,
} from "../../../../services/IntimationFailure";
import {
  Button,
  Card,
  Container,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { numberWithCommas } from "../../../../utils/Utility";
import SelectSearchDropDown from "../../reusableComponents/SelectSearchDropDown";

function IntimationFailiure() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const [intimationTriesDetail, setIntimationTriesDetail] =
    useState<IntimationTriesDetailResponse[]>();
  const [pageData, setPageData] = useState<any>({
    rowData: [],
    isLoading: false,
    totalPages: 10,
    totalRows: 0,
  });
  const [loader, setLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const initialFilter: IntimationFilter = {
    startDate: _newDate,
    endDate: _newDate,
    companyServiceId: "0",
    pageIndex: currentPage - 1,
    pageSize: 10,
    consumerNumber: "",
  };
  const isFirstRender = useRef(true);
  const [filter, setFilter] = useState<IntimationFilter>(initialFilter);
  const [intimations, setIntimations] = useState<IntimationFailureResponse[]>();
  const [showModal, setShowModal] = useState(false);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);

  const getCompanyOptions = () => {
    return companies.map((company) => ({
      label: company.getCompanyName(),
      options: company.getServices().map((service) => ({
        value: service.getId(),
        label: service.getServiceName(),
      })),
    }));
  };

  const handleSelect = (id: number) => {
    if (selectedRows.includes(id)) {
      const newSelections = selectedRows.filter((rowId) => {
        return rowId !== id;
      });
      setSelectedRows(newSelections);
    } else {
      setSelectedRows((prev) => [...prev, Number(id)]);
    }
  };

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      const allIds = pageData.rowData.map((row: any) => row.id);
      setSelectedRows(allIds);
    } else {
      setSelectedRows([]);
    }
  };

  const resetIntimation = async () => {
    if (selectedRows.length < 1) {
      Swal.fire({
        icon: "error",
        text: "Please select at least one intimation to reset",
      });
      return;
    }
    resetIntimations(selectedRows)
      .then((response) => {
        if (response.status === 200) {
          Swal.fire({
            icon: "success",
            title: "Intimations Reset",
            text: "The selected intimation(s) have been reset successfully",
          });
          getReportData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Intimations Reset Failed",
            text: "An error occurred while resetting the intimation(s)",
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Intimations Reset Failed",
          text: "An error occurred while resetting the intimation(s)",
        });
      })
      .finally(() => {
        setSelectedRows([]);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const onConsumerNumberClick = async (id: number) => {
    setLoader(true);
    const intimationTriesDetail = await getIntimationTriesDetail(id);
    setLoader(false);
    setIntimationTriesDetail(intimationTriesDetail?.data?.content);
    setShowModal(true);
  };

  const getReportData = async (init: boolean = false) => {
    if (moment(filter.startDate).isAfter(filter.endDate)) {
      Swal.fire({
        icon: "error",
        title: "Date Range Error",
        text: "From Date can not be greater than To Date",
      });
      return;
    }
    setPageData((prev: any) => ({ ...prev, isLoading: true }));
    const response = await getIntimationFailureReport(
      init
        ? initialFilter
        : {
            ...filter,
            pageSize: Number(filter.pageSize),
          }
    );
    if (response.status === 200) {
      const { content, totalAmount, totalRecord } = response.data;
      const psidObj: IntimationFailureResponse[] = content;
      setIntimations(psidObj);
      setPageData((prevState: any) => ({
        ...prevState,
        isLoading: false,
        rowData: psidObj
          ? psidObj.map((p: any, i) => {
              p.srno = (currentPage - 1) * pageSize + i + 1;
              p.isExpired = p.isExpired ? "Yes" : "No";
              return p;
            })
          : [],
        totalPages: 10,
        totalRows: Number(totalRecord),
      }));
    }
  };

  const _initFilters = () => {
    setFilter(initialFilter);

    const serviceDropdown = document.getElementById(
      "serviceDropdown"
    ) as HTMLSelectElement;
    if (serviceDropdown) {
      serviceDropdown.value = "0";
    }
    getReportData(true);
  };

  const onChangeStartDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const serviceChangeHandler = (e: any) => {
    let companyServiceId = "0";
    if (e?.target?.value) {
      companyServiceId = e.target.value;
    }
    setCurrentPage(1);
    setFilter((filter: any) => ({ ...filter, companyServiceId }));
  };

  function onSearchChange(value: any) {
    setFilter((filter: any) => ({
      ...filter,
      consumerNumber: value,
      pageIndex: 0,
    }));
  }

  const setPageIndex = (page: number) => {
    setCurrentPage(page);
    setFilter((filter: any) => ({ ...filter, pageIndex: page - 1 }));
  };

  const onLoadPageItems = (pageItem: number) => {
    setCurrentPage(1);
    setPageSize(pageItem);
    setFilter((filter: any) => ({
      ...filter,
      pageSize: pageItem,
      pageIndex: 0,
    }));
  };

  const COLUMNS = [
    {
      Header: (
        <div className="headerSelectionBox">
          <input
            type="checkbox"
            onChange={(e) => handleSelectAll(e.target.checked)}
            checked={selectedRows.length === pageSize}
          />
        </div>
      ),
      accessor: "select",
      Cell: ({ row }: any) => (
        <input
          type="checkbox"
          checked={selectedRows.includes(row.original.id)}
          onChange={() => handleSelect(row.original.id)}
        />
      ),
    },
    {
      Header: "Consumer Number",
      accessor: "consumerNumber",
    },
    {
      Header: "Challan Number",
      accessor: "challanNumber",
    },
    {
      Header: "Transaction Amount",
      accessor: "transactionAmount",
    },
    {
      Header: "Transaction Date",
      accessor: "transactionDate",
    },
    {
      Header: "Transaction Time",
      accessor: "transactionTime",
    },
    {
      Header: "Company Service",
      accessor: "companyService",
    },
    {
      Header: "Bank",
      accessor: "bank",
    },
  ];
  const columns: any = COLUMNS;

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      getReportData();
    }
  }, [currentPage, pageSize]);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Intimation Failure" });
    getCompanyList();
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">From Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">To Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
            />
          </div>

          <div className="col-md-3">
            <label className="filter-label">Company Service</label>
            <SelectSearchDropDown
              setFilter={(companyServiceId: string) =>
                setFilter((filter: any) => ({ ...filter, companyServiceId }))
              }
              companies={companies}
              setCurrentPage={setCurrentPage}
              selectedValue={filter.companyServiceId}
            />
          </div>
          <div className="col-md-3" />
          <div className="col-md-3">
            <label className="filter-label">Consumer Number</label>
            <input
              className="form-control"
              type="text"
              value={filter?.consumerNumber || ""}
              onChange={(e) => {
                const value = e.target.value.trim();
                if (
                  value === "" ||
                  (/^\d+$/.test(value) && value.length <= 14)
                ) {
                  onSearchChange(value);
                }
              }}
              placeholder="Consumer Number"
              maxLength={14}
            />
          </div>
          <div className="col-md-1" />
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={resetIntimation}
              style={{ width: "100%" }}
            >
              Reset Intimation
            </button>
          </div>
          {/* <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={undefined}
              style={{ width: "100%" }}
            >
              Download Excel
            </button>
          </div> */}
        </div>
      </div>
      {pageData?.rowData && (
        <div className="card p-3">
          <AppTable
            columns={columns}
            data={pageData.rowData.map((row: any) => ({
              ...row,
              consumerNumber: (
                <div
                  onClick={() => onConsumerNumberClick(row?.id)}
                  style={{
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onMouseOver={(e) =>
                    (e.currentTarget.style.textDecoration = "underline")
                  }
                  onMouseOut={(e) =>
                    (e.currentTarget.style.textDecoration = "none")
                  }
                >
                  {row.consumerNumber}
                </div>
              ),
              transactionAmount: numberWithCommas(row.transactionAmount),
            }))}
            isLoading={pageData.isLoading || loader}
          />
          <Pagination
            totalRows={pageData.totalRows}
            pageChangeHandler={setPageIndex}
            rowsPerPage={filter.pageSize}
            currentPage={currentPage}
            pageItemChangeHandler={onLoadPageItems}
          />
        </div>
      )}
      <Modal
        show={showModal}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-dialog-scrollable modal-lg"
      >
        <Modal.Header>
          <Modal.Title>Intimations Tries Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="d-flex flex-column">
            {intimationTriesDetail?.map((intimation, index) => {
              return (
                <Card key={index} className="mb-4">
                  <Card.Body>
                    <Card.Title>Intimation Detail {index + 1}</Card.Title>
                    <Card.Text>
                      <strong className="mr-2">Intimation Time:</strong>
                      {new Date(intimation.intimationTime).toLocaleString()}
                      <br />
                      <strong>Request URL:</strong>
                      {intimation.requestURL}
                      <br />
                      <br />
                      {intimation.request && (
                        <>
                          <Card
                            style={{
                              padding: "10px",
                            }}
                          >
                            <strong className="text-center">Request</strong>
                            {Object.keys(JSON.parse(intimation.request)).map(
                              (key) => (
                                <div key={key}>
                                  <strong className="mr-4">{key}:</strong>
                                  {JSON.parse(intimation.request)[key]}
                                </div>
                              )
                            )}
                          </Card>
                          <br />
                        </>
                      )}
                      <Card
                        style={{
                          padding: "10px",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div></div>
                          <div>
                            <strong className="text-center">Response</strong>
                          </div>
                          <OverlayTrigger
                            placement="top"
                            overlay={
                              <Tooltip id="tooltip">Copy to Clipboard</Tooltip>
                            }
                          >
                            <i
                              className="bi bi-clipboard-check-fill"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  JSON.stringify(intimation.response, null, 2)
                                );
                              }}
                              style={{ cursor: "pointer" }}
                            ></i>
                          </OverlayTrigger>
                        </div>

                        <pre className="mt-4">
                          {JSON.stringify(intimation.response, null, 2)}
                        </pre>
                      </Card>
                    </Card.Text>
                  </Card.Body>
                </Card>
              );
            })}
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default IntimationFailiure;
