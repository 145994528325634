export function dateFormat(date: string): string {
    const objDate = new Date(date); 

    const formattedDate = objDate.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
    });
    return formattedDate;
}

export function numberWithCommas(x: string) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}