import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../core/Auth";
import { login } from "../core/_requests";
import clsx from "clsx";
import { UserModel } from "../core/_models";
import ReCAPTCHA, { ReCAPTCHAProps } from "react-google-recaptcha";
import Swal from "sweetalert2";

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .min(6, "Minimum 6 characters")
    .max(50, "Maximum 11 characters")
    .required("Username is required"),
  password: Yup.string()
    .min(6, "Minimum 6 characters")
    .max(50, "Maximum 50 characters")
    .required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

function Login() {
  const [loading, setLoading] = useState(false);
  const { saveAuth, setCurrentUser } = useAuth();
  const recaptcha = useRef<ReCAPTCHA>(null);

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const onCaptchaChange = (val: any) => {
    setCaptchaVerified(!!val);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      const captchaValue = recaptcha.current?.getValue();
      if (!captchaValue) {
        Swal.fire({
          icon: "error",
          text: "Please select correct Recaptcha Value",
        });
        setSubmitting(false);
        return;
      }
      setLoading(true);
      try {
        const { data } = await login(values.email, values.password);

        const auth: UserModel = data.content[0];
        // console.log(auth);
        saveAuth(auth);
        // const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(auth);
      } catch (error) {
        console.error(error);
        // saveAuth(undefined)
        setStatus("The login details are incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  return (
    <div className="center-frmlogin-payzen">
      <div className="rgba-bg">
        <div className="row">
          <div className="offset-md-2 col-md-8">
            <div className="clearfix mb-3">
              <div
                className="title-login-payzen"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                Hello!
              </div>
              <div
                className="signin-text"
                data-aos="zoom-in-down"
                data-aos-duration="2000"
              >
                Sign in to your account
              </div>
            </div>
            <form onSubmit={formik.handleSubmit} noValidate id="login_form">
              {formik.status ? (
                <div className="mb-lg-15 alert alert-danger">
                  <div className="alert-text font-weight-bold">
                    {formik.status}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div
                className="col-sm-12 payzen-formgroup no-padding"
                data-aos="fade-left"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="2000"
              >
                <label className="payzen-label">USERNAME</label>
                <input
                  className={clsx(
                    "new-form-cntrl-payzen",
                    {
                      "is-invalid": formik.touched.email && formik.errors.email,
                    },
                    { "is-valid": formik.touched.email && !formik.errors.email }
                  )}
                  {...formik.getFieldProps("email")}
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="off"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="invalid-feedback">
                    {" "}
                    {formik.errors.email}{" "}
                  </div>
                )}
              </div>
              <div
                className="col-sm-12 payzen-formgroup no-padding"
                data-aos="fade-left"
                data-aos-anchor-placement="bottom-bottom"
                data-aos-duration="2000"
              >
                <label className="payzen-label">PASSWORD</label>
                <input
                  className={clsx(
                    "new-form-cntrl-payzen",
                    {
                      "is-invalid":
                        formik.touched.password && formik.errors.password,
                    },
                    {
                      "is-valid":
                        formik.touched.password && !formik.errors.password,
                    }
                  )}
                  id="password"
                  type="password"
                  {...formik.getFieldProps("password")}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="invalid-feedback">
                    {" "}
                    {formik.errors.password}{" "}
                  </div>
                )}
              </div>
              {/* <div className="col-sm-12 no-padding payzen-formgroup">
                            <Link className='forgot-password-payzen' to='/auth/forgot-password'>Forgot Your Password?</Link>
                        </div> */}
              <div className="clearfix"></div>
              <div className="d-flex justify-content-center">
                <ReCAPTCHA
                  ref={recaptcha}
                  sitekey={process.env.REACT_APP_RECAPTCHA_SITE || ""}
                  onChange={onCaptchaChange}
                  theme={"dark"}
                  size="normal"
                />
              </div>
              <div className="col-sm-12 no-padding mt-2">
                <button
                  type="submit"
                  id="sign_in_submit"
                  className="btn-login-payzen"
                  disabled={
                    formik.isSubmitting || !formik.isValid || !captchaVerified
                  }
                >
                  {!loading && <span className="indicator-label">Login</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: "block" }}
                    >
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              </div>
              {/* <div className="col-sm-12 no-padding">
                            <label className="payzen-label">Need an account? 
                                <Link to="/auth/register">REGISTER</Link>
                            </label>
                        </div>     */}
            </form>
          </div>
          {/* <div className="offset-md-2 col-md-8">
                    <div className="clearfix mb-3">
                        <div className="title-login-payzen">Validate OTP</div>
                        <div className="signin-text">Enter OTP received via SMS / Email</div>
                    </div>
                    <b-form @submit="verifyOTP">
                        <div className="col-sm-12 payzen-formgroup no-padding">
                            <label className="payzen-label">Enter OTP</label>
                            <b-form-input 
                                className="new-form-cntrl-payzen"
                                type="text"
                                id="otp" 
                                name="otp" 
                                v-model="$v.otpForm.otp.$model"
                                :state="otpValidateState('otp')"
                                aria-describedby="input-otp-feedback"/>
                            <b-form-invalid-feedback id="input-otp-feedback">
                                OTP will expires in.
                            </b-form-invalid-feedback>
                        </div>
                        <div className="clearfix"></div>
                        <div className="col-sm-12 no-padding">
                            <button type="submit" className="btn-login-payzen">VERIFY</button>
                        </div>
                    </b-form>
                </div> */}
          {/* <div className="col-md-5">
                    <div className="qr-image-div">
                        <img src="../images/qr.jpg" alt="logo" className="img-responsive" />
                    </div>
                    <div className="title-login-payzen">Log in with QR Code</div>
                    <label className="payzen-label2">Scan this with <b>Payzen</b> mobile <br /><b>app</b> to log in instantly.</label>
                </div> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
