import React, { useState } from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { changePassword } from '../../../../services/UserService';
import clsx from 'clsx';
import { Button } from 'react-bootstrap';


const initialValues = {
    oldPassword: '',
    password: '',
    confirmPassword: ''
}

const changePasswordSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(20, 'Maximum 20 characters')
        .required('Old Password is required'),
    password: Yup.string()
        .min(6, 'Minimum 6 characters')
        .max(20, 'Maximum 20 characters')
        .required('New Password is required'),
    confirmPassword: Yup.string()
        .required('New Password confirmation is required')
        .when('password', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
        })
});

function ChangePassword() {
    const [loading, setLoading] = useState(false)
    const [successMsg, setSuccessMsg] = useState('');

    const cpForm = useFormik({
        initialValues,
        validationSchema: changePasswordSchema,
        onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
          setLoading(true)
          setSuccessMsg('');
          try {
            const {data} = await changePassword({
                password: values.oldPassword,
                newPassword: values.password,
                confirmNewPassword: values.confirmPassword
            })
            setSuccessMsg(data.message);
          } catch (error) {
            const err: any  = error;
            setStatus(err.response.data.message)
            setSubmitting(false)
            setLoading(false)
          }
        },
      })
  return (
    <div className="dashboard-middle-content">
        <div className="card mb-5 p-3">
            <h6>Update Password</h6>
            <div className="row">
                <div className="col-md-6">
                    <form 
                        onSubmit={cpForm.handleSubmit}
                        noValidate
                        id='register_form'
                        >
                        {cpForm.status ? (
                            <div className='mb-lg-15 alert alert-danger'>
                            <div className='alert-text font-weight-bold'>{cpForm.status}</div>
                            </div>
                        ) : (
                            <>
                            </>
                        )}
                        {
                            successMsg ? (
                                <div className='mb-lg-15 alert alert-success'>
                                <div className='alert-text font-weight-bold'>{successMsg}</div>
                                </div>
                            ) : (
                                <>
                                </>
                            )
                        }
                        <div className="col-sm-12 payzen-formgroup no-padding">
                            <label className="payzen-label">Old Password</label>
                            <input 
                                className={
                                    clsx('form-control', 
                                        { 'is-invalid': cpForm.touched.oldPassword && cpForm.errors.oldPassword }, 
                                        { 'is-valid': cpForm.touched.oldPassword && !cpForm.errors.oldPassword }
                                    )
                                }
                                {...cpForm.getFieldProps('oldPassword')}
                                type="password"
                                id="oldPassword" 
                                name="oldPassword" 
                                autoComplete='off'/>
                            {
                                cpForm.touched.oldPassword && cpForm.errors.oldPassword && (
                                    <div className='invalid-feedback'> {cpForm.errors.oldPassword} </div>
                                )
                            }
                        </div>
                        <div className="col-sm-12 payzen-formgroup no-padding">
                            <label className="payzen-label">New Password</label>
                            <input 
                                className={
                                    clsx('form-control', 
                                        { 'is-invalid': cpForm.touched.password && cpForm.errors.password }, 
                                        { 'is-valid': cpForm.touched.password && !cpForm.errors.password }
                                    )
                                }
                                {...cpForm.getFieldProps('password')}
                                type="password"
                                id="password" 
                                name="password" 
                                autoComplete='off'/>
                            {
                                cpForm.touched.password && cpForm.errors.password && (
                                    <div className='invalid-feedback'> {cpForm.errors.password} </div>
                                )
                            }
                        </div>
                        <div className="col-sm-12 payzen-formgroup no-padding">
                            <label className="payzen-label">Confirm New Password</label>
                            <input 
                                className={
                                    clsx('form-control', 
                                        { 'is-invalid': cpForm.touched.confirmPassword && cpForm.errors.confirmPassword }, 
                                        { 'is-valid': cpForm.touched.confirmPassword && !cpForm.errors.confirmPassword }
                                    )
                                }
                                {...cpForm.getFieldProps('confirmPassword')}
                                type="password"
                                id="confirmPassword" 
                                name="confirmPassword" 
                                autoComplete='off'/>
                            {
                                cpForm.touched.confirmPassword && cpForm.errors.confirmPassword && (
                                    <div className='invalid-feedback'> {cpForm.errors.confirmPassword} </div>
                                )
                            }
                        </div>
                        <Button variant="primary" type='submit'>
                            Update
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ChangePassword