import axios from "axios";
import { CompanyRequest } from "../modules/app/models/CompanyRequest";
import { ICompanyRequest } from "../modules/app/models/interfaces/ICompanyRequest";

const API_URL = process.env.REACT_APP_API_URL

//permissions api urls
export const PERMISSION_SAVE = `${API_URL}/permissions/save`
export const PERMISSION_UPDATE = `${API_URL}/permissions/update`
export const PERMISSION_GET = `${API_URL}/permissions/`
export const PERMISSION_STATUS = `${API_URL}/permissions/status`

//interfaces apis urls
export const INTERFACE_SAVE = `${API_URL}/interfaces/save`
export const INTERFACE_UPDATE = `${API_URL}/interfaces/update`
export const INTERFACE_GET = `${API_URL}/interfaces/`
export const INTERFACE_STATUS = `${API_URL}/interfaces/status`

//Roles apis urls
export const ROLE_SAVE = `${API_URL}/roles/save`
export const ROLE_UPDATE = `${API_URL}/roles/update`
export const ROLE_GET = `${API_URL}/roles/`
export const ROLE_FIND = `${API_URL}/roles/find`
export const ROLE_STATUS = `${API_URL}/roles/status`


// Company Requests 

export const REGISTERED_REQUESTS_URL = `${API_URL}/user/getRegisteredRequests`
export const APPROVAL_REQUEST_URL = `${API_URL}/user/sendApproval`
export const GET_USER_LIST = `${API_URL}/user/`
export const SAVE_USER = `${API_URL}/user/save`
export const UPDATE_USER = `${API_URL}/user/update`
export const GET_USER = `${API_URL}/user/find`
export const UPDATE_USER_STATUS = `${API_URL}/user/status`

export const UPDATE_PASSWORD_URL = `${API_URL}/auth/profile/updatePassword`
export const UPDATE_PASSWORD_USER_URL = `${API_URL}/user/updatePassword`




//apis for permissions
export function savePermissions(postdata: any | undefined) {
    return axios.post(PERMISSION_SAVE, postdata)
}

export const updatePermissions = (postdata:any | undefined): Promise<any> => {
    return axios.post(PERMISSION_UPDATE, postdata)
}
export const getPermissions = (): Promise<any> => {
    return axios.get(PERMISSION_GET)
}
export const updatePermissionsStatus = (postdata:any | undefined): Promise<any> => {
    return axios.post(PERMISSION_STATUS, postdata)
}


// apis for Interfaces
export function saveInterfaces(postdata: any | undefined) {
    return axios.post(INTERFACE_SAVE, postdata)
}

export const updateInterfaces = (postdata:any | undefined): Promise<any> => {
    return axios.post(INTERFACE_UPDATE, postdata)
}
export const getInterfaces = (): Promise<any> => {
    return axios.get(INTERFACE_GET)
}
export const updateInterfacesStatus = (postdata:any | undefined): Promise<any> => {
    return axios.post(INTERFACE_STATUS, postdata)
}


// apis for Roles
export function saveRoles(postdata: any | undefined) {
    return axios.post(ROLE_SAVE, postdata)
}

export const updateRoles = (postdata:any | undefined): Promise<any> => {
    return axios.post(ROLE_UPDATE, postdata)
}
export const getRoles = (): Promise<any> => {
    return axios.get(ROLE_GET)
}
export const findRole = (id: number): Promise<any> => {
    return axios.get(ROLE_FIND+'?id='+id)
}
export const updateRolesStatus = (postdata:any | undefined): Promise<any> => {
    return axios.post(ROLE_STATUS, postdata)
}

export const getRegisteredRequests = (payload: any = {}): Promise<ICompanyRequest[]> => {
    return axios.get<ICompanyRequest[]>(REGISTERED_REQUESTS_URL, payload)
            .then((res: any) => res.data.content.map((d: any) => new CompanyRequest(d)))
            .catch(err => console.log(err)
        )
    // return axios.get(REGISTERED_REQUESTS_URL, payload)
}

export const sendApprovalRequest = (payload: any): Promise<any> => {
    return axios.post(APPROVAL_REQUEST_URL, payload)
}

export const getUsers = (): Promise<any> => {
    return axios.get(GET_USER_LIST)
}

export const updateUserStatus = (postdata:any | undefined): Promise<any> => {
    return axios.post(UPDATE_USER_STATUS, postdata)
}

export const saveUser = (postdata: any | undefined): Promise<any> =>  {
    return axios.post(SAVE_USER, postdata)
}

export const updateUser = (postdata:any | undefined): Promise<any> => {
    return axios.post(UPDATE_USER, postdata)
}

export const changePassword = (postdata:any | undefined): Promise<any> => {
    return axios.post(UPDATE_PASSWORD_URL, postdata)
}

export const changePasswordUserList = (postdata:any | undefined): Promise<any> => {
    return axios.post(UPDATE_PASSWORD_USER_URL, postdata)
}

