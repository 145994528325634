import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getTransactionSummaryAccountWise,
  getTransactionSummaryDetail,
} from "../../../../services/PsidService";
import {
  ITransactionSummaryResponse,
  ITransactionSummaryFilter,
  ICompanyTransactionSummary,
  IServiceSettlementResponse,
} from "../../models/interfaces";
import { useAuth } from "../../../auth/core/Auth";
import { Button, Modal } from "react-bootstrap";
import { IServiceBifurcationResponse } from "../../models/interfaces/IServiceBifurcationResponse";
import { getCompanyTransactionsSummary } from "../../../../services/CompanySettlement";

function CompanySettlements() {
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const transactionType: any = useMemo(() => ["1BILL", "CARD"], []);

  const initialFilter = {
    startDate: _newDate,
    endDate: _newDate,
  };

  const [filter, setFilter] = useState<ITransactionSummaryFilter>({
    startDate: _newDate,
    endDate: _newDate,
  });

  const [_, setTransactionSummary] = useState<ITransactionSummaryResponse[]>();
  const [companyTransactionSummary, setCompanyTransactionSummary] =
    useState<ICompanyTransactionSummary[]>();
  const [totalChallanAmount, setTotalChallanAmount] = useState<Number>(0);
  const [totalPitbCharges, setTotalPitbCharges] = useState<Number>(0);
  const [transactionBifurcation, setTransactionBifurcation] =
    useState<IServiceBifurcationResponse[]>();
  const [serviceBifurcation, setServiceBifurcation] = useState<string>("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  useEffect(() => {
    updateHeaderContext({ pageTitle: "Company Settlements" });
    getReportData();
  }, []);

  const getReportData = async (init: boolean = false) => {
    setShow(false);
    setCompanyTransactionSummary([]);
    setTotalChallanAmount(0);
    const response = await getCompanyTransactionsSummary(
      init ? initialFilter : filter
    );
    if (response.status === 200) {
      const { content } = response.data;
      const transactionSummaryObj: ITransactionSummaryResponse[] = content;
      let totalChallan = 0;
      let totalCharges = 0;
      setTransactionSummary(transactionSummaryObj);
      let companySummaryList: ICompanyTransactionSummary[] = [];
      if (transactionSummaryObj.length) {
        transactionSummaryObj.forEach(
          (obj: ITransactionSummaryResponse, index) => {
            const existedCompanySummary = companySummaryList.find(
              (ecs) => ecs.companyId == obj.companyId
            );
            if (existedCompanySummary != null) {
              existedCompanySummary.companyTotalChallanAmount =
                existedCompanySummary.companyTotalChallanAmount +
                Number(obj.challanAmount);
              existedCompanySummary.companyTotalPitbCharges =
                existedCompanySummary.companyTotalPitbCharges +
                Number(obj.pitbCharges);
              existedCompanySummary.serviceTransactionSummary.push({
                serviceName: obj.serviceName,
                settlementMode: obj.settlementMode,
                challanAmount: obj.challanAmount,
                pitbCharges: obj.pitbCharges,
                serviceId: obj.serviceId,
              });
            } else {
              companySummaryList.push({
                companyId: obj.companyId,
                companyName: obj.companyName,
                companyTotalChallanAmount: Number(obj.challanAmount),
                companyTotalPitbCharges: Number(obj.pitbCharges),
                serviceTransactionSummary: [
                  {
                    serviceName: obj.serviceName,
                    settlementMode: obj.settlementMode,
                    challanAmount: obj.challanAmount,
                    pitbCharges: obj.pitbCharges,
                    serviceId: obj.serviceId,
                  },
                ],
              });
            }
            totalChallan =
              totalChallan +
              (obj.challanAmount ? Number(obj.challanAmount) : 0);
            totalCharges =
              totalCharges + (obj.pitbCharges ? Number(obj.pitbCharges) : 0);
            setTotalChallanAmount(totalChallan);
            setTotalPitbCharges(totalCharges);
          }
        );
        setCompanyTransactionSummary(companySummaryList);
      }
    }
  };

  const handleShow = async (companyId: number) => {
    setTransactionBifurcation([]);
    // setServiceBifurcation(serviceName);
    const response = await getTransactionSummaryAccountWise({
      startDate: filter.startDate,
      endDate: filter.endDate,
      companyId: companyId,
    });
    if (response.status === 200) {
      const data = response.data.content;
      setTransactionBifurcation(data);
      // console.log(data);
      setShow(true);
      setServiceBifurcation(String(companyId));
    }
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  const onChangeStartDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, startDate: e.target.value }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, endDate: e.target.value }));
  };

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  var rows =
    companyTransactionSummary &&
    companyTransactionSummary.length > 0 &&
    companyTransactionSummary.map(
      (row: ICompanyTransactionSummary, index: any) => {
        return (
          <>
            <tr
              key={index + 50}
              onClick={() => {
                if (serviceBifurcation !== String(row.companyId))
                  handleShow(row.companyId);
                else if (serviceBifurcation === String(row.companyId) && !show)
                  handleShow(row.companyId);
                else setShow(false);
              }}
              style={{ cursor: "pointer" }}
            >
              <td>
                <b className="mr-4">{row.companyName}</b>
                {show && serviceBifurcation === String(row.companyId) ? (
                  <i className="fa fa-angle-up" aria-hidden="true"></i>
                ) : (
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                )}
              </td>
            </tr>
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 0 &&
              row.serviceTransactionSummary.map(
                (crow: IServiceSettlementResponse, cindex: any) => {
                  return (
                    <tr key={cindex}>
                      <td></td>
                      <td>{crow.serviceName}</td>
                      <td>{Number(crow.challanAmount).toLocaleString()}</td>
                    </tr>
                  );
                }
              )}
            {show && serviceBifurcation && (
              <tr>
                <td colSpan={4}>
                  <table>
                    <thead>
                      <tr>
                        <th>Account Title</th>
                        <th>Account Number</th>
                        <th>Settlement Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactionBifurcation?.length != undefined &&
                        transactionBifurcation.map(
                          (tbRow: IServiceBifurcationResponse, index: any) => (
                            <tr>
                              <td>{tbRow.accountTitle}</td>
                              <td>{tbRow.accountNumber}</td>
                              <td className="text-center">
                                {tbRow.challanAmount.toLocaleString()}
                              </td>
                            </tr>
                          )
                        )}
                      {transactionBifurcation?.length == 0 && (
                        <tr>
                          <td />
                          <div
                            style={{ textAlign: "center", marginTop: "20px" }}
                          >
                            No record found.
                          </div>
                          <td />
                        </tr>
                      )}
                    </tbody>
                  </table>
                </td>
              </tr>
            )}
            {row.serviceTransactionSummary &&
              row.serviceTransactionSummary.length > 1 && (
                <tr key={index}>
                  <td colSpan={2} className="text-right">
                    <b>Company Total</b>
                  </td>
                  <td>{row.companyTotalChallanAmount.toLocaleString()}</td>
                </tr>
              )}
          </>
        );
      }
    );

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">From Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.startDate || ""}
              onChange={onChangeStartDate}
            />
          </div>
          <div className="col-md-3">
            <label className="filter-label">To Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.endDate || ""}
              onChange={onChangeEndDate}
            />
          </div>
          <div className="col-md-2">
            {/* <label className="filter-label">Transaction Type</label>
            <select className="form-control" onChange={statusChangeHandler}>
              {transactionType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select> */}
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {companyTransactionSummary?.length != undefined && (
        <div className="card p-3">
          <div
            className="mt-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            style={{ margin: "20px" }}
          >
            <table>
              <thead>
                <tr>
                  <th>Company Name</th>
                  <th>Service Name</th>
                  <th>Company Charges</th>
                </tr>
              </thead>
              <tbody>
                {rows}
                {totalChallanAmount != 0 && (
                  <tr
                    key={
                      companyTransactionSummary &&
                      companyTransactionSummary?.length + 1
                    }
                  >
                    <td colSpan={2} className="text-right">
                      <b>Grand Total</b>
                    </td>
                    <td>{totalChallanAmount.toLocaleString()}</td>
                  </tr>
                )}
                {companyTransactionSummary?.length == 0 && (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanySettlements;
