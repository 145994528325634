import moment from "moment";
import React, { useEffect, useState, useMemo } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { dt } from "../../../../helpers/AppHelpers";
import {
  getCompanies,
  getServiceWiseSettlement,
} from "../../../../services/PsidService";
import {
  ICompany,
  IServiceSettlementResponse,
  IServiceSettlementFilter,
} from "../../models/interfaces";
import { useAuth } from "../../../auth/core/Auth";
import CompanySearchDropDown from "../../reusableComponents/CompanySearchDropDown";

function ServiceWiseSettlement() {
  const { auth } = useAuth();

  // const data = useMemo(() => MOCK_DATA, [])
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = useMemo(
    () => moment(new Date()).format(dt.dbDateFormat),
    []
  );
  const transactionType: any = useMemo(() => ["1BILL", "CARD"], []);
  const [companies, setCompanies] = useState<ICompany[]>([]);
  const initialFilter = {
    toDate: _newDate,
    companyId: "",
    transactionType: "1BILL",
  };
  const [filter, setFilter] = useState<IServiceSettlementFilter>({
    toDate: _newDate,
    companyId: "",
    transactionType: "1BILL",
  });
  const [serviceSettlement, setServiceSettlement] =
    useState<IServiceSettlementResponse[]>();
  useEffect(() => {
    updateHeaderContext({ pageTitle: "Service Wise Settlement" });
    getCompanyList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   getReportData();
  // }, [companies]);

  // useEffect(()=> {
  //     console.log(auth?.role?.roleType);
  // }, [paymentTransaction])

  const showCompanyDropdown = (): boolean => {
    return ["ROLE_PITB_ADMIN", "ROLE_ADMIN", "PITB_FINANCE"].includes(
      auth?.role?.roleType
    );
  };

  const isDepartmentAdmin = (): boolean => {
    return auth?.role?.roleType == "ROLE_DEPARTMENT_ADMIN";
  };

  const getCompanyList = async () => {
    const response = await getCompanies();
    setCompanies(response);
  };

  const getReportData = async (init: boolean = false) => {
    const response = await getServiceWiseSettlement(
      init ? initialFilter : filter
    );
    if (response.status === 200) {
      const { content } = response.data;
      const serviceSettlementObj: IServiceSettlementResponse[] = content;
      setServiceSettlement(serviceSettlementObj);
    }
  };

  // OnChange Handler

  const onChangeToDate = (e: any) => {
    setFilter((filter: any) => ({ ...filter, toDate: e.target.value }));
  };

  const companyChangeHandler = (e: any) => {
    let companyId = "0";
    if (e.target.value) {
      // const company = companies.find(c => c.getId() === e.target.value)
      // setCompany(company);
      companyId = e.target.value;
    }
    setFilter((filter: any) => ({ ...filter, companyId }));
  };

  function exportExcel(): void {}

  const _initFilters = () => {
    setFilter(initialFilter);
    getReportData(true);
  };

  const statusChangeHandler = (e: any) => {
    const transactionType = e.target.value;
    setFilter((filter: any) => ({ ...filter, transactionType }));
  };

  var rows =
    serviceSettlement &&
    serviceSettlement.length > 0 &&
    serviceSettlement.map((row: IServiceSettlementResponse, index: any) => {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{row.serviceName}</td>
          <td>{row.settlementMode}</td>
          <td>{row.transactionDate}</td>
          <td>{Number(row.challanAmount).toLocaleString()}</td>
          <td>{Number(row.pitbCharges).toLocaleString()}</td>
        </tr>
      );
    });

  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
        <div className="row">
          <div className="col-md-3">
            <label className="filter-label">Date</label>
            <input
              className="dashboard-top-filter-input mb-2"
              type="date"
              value={filter?.toDate || ""}
              onChange={onChangeToDate}
            />
          </div>
          {showCompanyDropdown() && (
            <div className="col-md-3">
              <label className="filter-label">Company</label>
              {/* <select className='form-control' onChange={companyChangeHandler}>
                                <option value="">Select Company</option>
                                {
                                    companies.map((c: ICompany) =>(<option key={c.getId()} value={c.getId()}>{c.getCompanyName()}</option>))
                                }
                            </select> */}
              <CompanySearchDropDown
                companies={companies}
                onChange={companyChangeHandler}
                value={filter.companyId ? filter.companyId : ""}
                allCompanies={false}
              />
            </div>
          )}
          <div className="col-md-2">
            <label className="filter-label">Transaction Type</label>
            <select
              className="form-control"
              onChange={statusChangeHandler}
              value={filter.transactionType}
            >
              {transactionType.map((ps: string) => (
                <option key={ps} value={ps}>
                  {ps}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttonblue"
              onClick={() => getReportData()}
              style={{ width: "100%" }}
              disabled={filter.companyId === ""}
            >
              Filter
            </button>
          </div>
          <div className="col-md-2">
            <button
              className="mt-8 dashboard-filter-buttongrey"
              onClick={_initFilters}
              style={{ width: "100%" }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {serviceSettlement?.length != undefined && (
        <div className="card p-3">
          <div
            className="mt-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            style={{ margin: "20px" }}
          >
            <table>
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Service Name</th>
                  <th>Settlement Mode</th>
                  <th>Transaction Date</th>
                  <th>Challan Amount</th>
                  <th>PITB Charges</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}

export default ServiceWiseSettlement;
