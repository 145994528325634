import React from 'react';
// import logo from './logo.svg';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Outlet } from 'react-router-dom';

AOS.init();

function App() {
  return (
    <div className="App">
        <Outlet />
      </div>
  );
}

export default App;
