import React, { useState, useEffect, useContext } from "react";
import { DashboardFilter } from "../models/DashboardFilter";
import moment from "moment";
import { dt } from "../../../helpers/AppHelpers";
import { getDashboardStats } from "../../../services/PsidService";
import { useUpdateHeaderContext } from "../../../contexts/HeaderContext";
import { useAuth } from "../../auth/core/Auth";

export default function Dashbord() {
  const { auth } = useAuth();
  const updateHeaderContext = useUpdateHeaderContext();
  const _newDate = moment(new Date()).format(dt.dbDateFormat);
  const [filter, setFilter] = useState<any>({
    startDate: _newDate,
    endDate: _newDate,
  });
  const [loading, setLoading] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState<Number>(0);
  const [totalRevenue, setTotalRevenue] = useState<Number>(0);
  const [stats, setStats] = useState([]);

  useEffect(() => {
    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps

    updateHeaderContext({ pageTitle: "Dashboard" });
  }, []);

  useEffect(() => {
    prepareStats();
  }, [stats]);

  const _initFilters = () => {
    setFilter({
      startDate: _newDate,
      endDate: _newDate,
    });
    getStats(true);
  };

  const onChangeStartDate = (e: any) => {
    setFilter((filter: any) => ({
      ...filter,
      startDate: e.target.value,
    }));
  };

  const onChangeEndDate = (e: any) => {
    setFilter((filter: any) => ({
      ...filter,
      endDate: e.target.value,
    }));
  };

  const getStats = async (init: boolean = false) => {
    setLoading(true);
    const payload: DashboardFilter = {
      startDate: filter.startDate,
      endDate: filter.endDate,
    };
    const initPayload = {
      startDate: _newDate,
      endDate: _newDate,
    };
    const response = await getDashboardStats(init ? initPayload : payload);
    if (response.status === 200) {
      // console.log(response.data.content[0]);
      const stats = response.data.content?.length ? response.data.content : [];
      setStats(stats);
    }
    setLoading(false);
  };

  const prepareStats = () => {
    let totalTransactions = 0;
    let totalRevenue = 0;
    if (stats.length > 0) {
      stats.forEach((s: any) => {
        totalTransactions += Number(s.transactionCount);
        totalRevenue += parseFloat(s.transactionAmount);
      });
      // totalRevenue = totalRevenue;
    }
    setTotalTransactions(totalTransactions);
    setTotalRevenue(totalRevenue);
  };

  // const numberWithCommas = (x:number) => {
  //     return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  // }
  const isPitbChargesShow = (): boolean => {
    return auth?.role?.roleType == "PITB_FINANCE";
  };

  return (
    <div className="dashboard-middle-content">
      <div className="row">
        <div className="col-md-4">
          <div className="dashboard-top-filter">
            <label className="from-date">From Date</label>
            <input
              className="dashboard-top-filter-input"
              type="date"
              value={filter?.startDate}
              onChange={onChangeStartDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-top-filter">
            <label className="from-date">To Date</label>
            <input
              className="dashboard-top-filter-input"
              type="date"
              value={filter?.endDate}
              onChange={onChangeEndDate}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="dashboard-top-filter dashboard-top-filter2">
            <button
              className="dashboard-filter-buttonblue-main"
              onClick={() => getStats()}
            >
              Filter
            </button>
            <button
              className="dashboard-filter-buttongrey-main"
              onClick={_initFilters}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
          }}
        >
          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
        </div>
      ) : (
        <div className="row">
          <div className="col-md-4">
            <div className="dashboard-dashboardboxes-main">
              <div className="services-heading">Number of Transaction</div>
              <div className="dashboard-dashboardboxes">
                <div
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  className="amount-trans"
                >
                  {totalTransactions.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="dashboard-dashboardboxes-main">
              <div className="services-heading">Revenue Collected</div>
              <div className="dashboard-dashboardboxes dashboard-dashboardboxes-gradient">
                <div
                  data-aos="fade-left"
                  data-aos-duration="2000"
                  className="amount-trans"
                >
                  {" "}
                  PKR {totalRevenue.toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4"></div>
          {stats.map((s: any) => (
            <div className="col-md-4" key={s.serviceId}>
              <div className="dashboard-dashboardboxes-main">
                <div className="services-heading">{s.serviceName}</div>
                <div className="dashboard-dashboardboxes dashboard-dashboardboxes-transparent">
                  <div className="no-of-transdashboard">
                    Number Of Transactions
                  </div>
                  <h3 className="nmbrs-amountdashboard mb-2">
                    {s.transactionCount
                      ? Number(s.transactionCount).toLocaleString()
                      : "0"}
                  </h3>
                  <div className="transactionAmount">
                    <div>
                      <div className="no-of-transdashboard">
                        Amount Collected
                      </div>
                      <h3 className="nmbrs-amountdashboard">
                        PKR{" "}
                        {s.transactionAmount
                          ? Number(s.transactionAmount).toLocaleString()
                          : "0"}
                      </h3>
                    </div>
                    {isPitbChargesShow() && (
                      <div>
                        <div className="no-of-transdashboard">PITB Charges</div>
                        <h3 className="no-of-transdashboard pitbCharges">
                          PKR{" "}
                          {s.serviceCharges
                            ? Number(s.serviceCharges).toLocaleString()
                            : "0"}
                        </h3>
                      </div>
                    )}
                  </div>
                  <div
                    data-aos="flip-right"
                    data-aos-duration="2000"
                    className="services-icons"
                  >
                    <img
                      src="../../images/electricity.png"
                      alt="logo"
                      className="img-responsive"
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
