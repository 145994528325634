import { ErrorMessage, Field, Form, Formik, useFormik } from 'formik';
import React, { useEffect, useState, useMemo, useCallback } from 'react'
import * as Yup from 'yup'

import { getInterfaces, updateInterfacesStatus, updateInterfaces, saveInterfaces } from './../../../services/UserService';
import clsx from 'clsx';
import { useUpdateHeaderContext } from '../../../contexts/HeaderContext';

let styleObj = {
  fontSize: 14,
  color: "#4a54f1",
  paddingTop: "100px"
}

let buttonStyles = {
  fontSize: 14,
  color: "#4a54f1",
  margin: 5
}


const submitScheme = Yup.object().shape({
  interfaceName: Yup.string()
    .min(3, 'Minimum 3 character')
    .max(20, 'Maximum 20 character')
    .required('Name is required'),
  interfaceLink: Yup.string()
    .min(1, 'Minimum 1 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Link is required'),
  menuLocation: Yup.string(),
  parentLink: Yup.string(),
})

const initialValues = {
  interfaceName: '',
  interfaceLink: '',
  menuLocation: '',
  parentLink: ''
}

function UserInterfaces() {

  const updateHeaderContext = useUpdateHeaderContext()
  const menuLocations: any = useMemo(() => ["OTHER", "SIDEBAR", "TOPBAR", "MAINMENU"], [])
  const [interfaces, setInterfaces] = useState<any>([])
  const [menuLocationSelect, setMenuLocationSelect] = useState('')
  const [formValues, setFormValues] = useState<any>(initialValues)

  // const [menuLocations] = useState<any>(["OTHER", "SIDEBAR", "TOPBAR", "MAINMENU"])

  useEffect(() => {
    updateHeaderContext({pageTitle: 'User Interfaces'})

    getInterfacesList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isSending, setIsSending] = useState(false)
  useCallback(async (id: any, status: any) => {
    // don't send again while we are sending
    if (isSending) return
    const postdata = {
      id: id,
      active: status
    }
    // update state
    setIsSending(true)
    // send the actual request
    await updateInterfacesStatus(postdata)
    // once the request is sent, update state again
    setIsSending(false)
  }, [isSending]) // update the callback if the state changes


  const [loading, setLoading] = useState(false)
  const [isPermissionUpdate, setPermissionUpdate] = useState(false)
  const [permissionId, setPermissionID] = useState(0)
  const [interfaceForm, setInterfaceForm] = useState(false)

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: submitScheme,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      var perId = 0;
      try {
        if (isPermissionUpdate) {
          perId = permissionId
        }
        const postdata = {
          id: perId,
          interfaceType: values.interfaceName,
          interfaceLink: values.interfaceLink,
          parentInterface: '',
          menuLocation: menuLocationSelect
        }
        if (isPermissionUpdate) {
          await updateInterfaces(postdata)
        } else {
          await saveInterfaces(postdata)
        }
        setSubmitting(false)
        setLoading(false)
        getInterfacesList()
        values.interfaceName = "";
        values.interfaceLink = "";
        setPermissionUpdate(false)
        setPermissionID(0)

      } catch (error) {
        console.error(error)
        // saveAuth(undefined)
        setStatus('The fail to submit')
        setSubmitting(false)
        setLoading(false)

      }
    },
  })

  const updateValues = (interfaceObj: any) => {
    setInterfaceForm(true);
    setFormValues({
      interfaceName: interfaceObj.interfaceType,
      interfaceLink: interfaceObj.interfaceLink,
      menuLocation: interfaceObj.menuLocation,
      parentLink: interfaceObj.parentInterface ? interfaceObj.parentInterface : ''
    })
    setPermissionUpdate(true);
    setPermissionID(interfaceObj.id);
  }

  const getInterfacesList = async () => {
    const response = await getInterfaces();
    setInterfaces(response.data.content)
  }
  const updateStatus = async (id: any, status: any) => {
    const postData = {
      id: id,
      activated: status
    }
    await updateInterfacesStatus(postData)
    getInterfacesList()
  }

  const meunLocationChangeHandler = (e: any) => {
    let menuText = '';
    if (e.target.value) {
      menuText = e.target.value
    }

    setMenuLocationSelect(menuText);
  }

  const handleSubmit = async (data: any) => {
    setLoading(true)
    var perId = 0;
    try {
      if (isPermissionUpdate) {
        perId = permissionId
      }
      const postdata = {
        id: perId,
        interfaceType: data.interfaceName,
        interfaceLink: data.interfaceLink,
        parentInterface: data.parentLink,
        menuLocation: data.menuLocation
      }

      if (isPermissionUpdate) {
        await updateInterfaces(postdata)
      } else {
        await saveInterfaces(postdata)
      }
      setLoading(false)
      getInterfacesList()
      setPermissionUpdate(false)
      setPermissionID(0)
      cancelRequest()

    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  const cancelRequest = () => {
    setFormValues(initialValues)
    setInterfaceForm(false);
  }

  const showInterfaceForm = () => {
    setInterfaceForm(true);
  }

  var rows = interfaces && interfaces.length > 0 && interfaces.map((row: any, index: any) => {
    return <tr key={index}>
      <td>{index + 1}</td>
      <td>{row.id}</td>
      <td>{row.interfaceType}</td>
      <td>{row.interfaceLink}</td>
      <td>{row.parentInterface}</td>
      <td>{row.menuLocation}</td>
      <td className='text-center'><button className={ row.active ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'} onClick={async () => { await updateStatus(row.id, !row.active) }}>{ row.active ? 'Active' : 'Inactive' }</button></td>
      <td className='text-center'>
        <button className='btn btn-sm btn-info ml-3' onClick={() => updateValues(row)}>Edit</button>
      </td>
    </tr>
  })

  return (
    <div className="container-fluid" style={styleObj}>
      <h3>Interfaces</h3>
      <div className="col-md-12">
        <div className="white-bg">
          <div className="float-right">
            {!interfaceForm && (<button className="btn btn-sm btn-info mb-3" onClick={showInterfaceForm}>Add Interface</button>)}
          </div>
          {interfaceForm && (<div data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" style={{ backgroundColor: 'white', padding: '20px' }}>
            <Formik
              initialValues={formValues}
              validationSchema={submitScheme}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ errors, touched, resetForm }) => (
                <Form>
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <div><label className="labelName">Name</label></div>
                      <Field
                        className={
                          'form-control w-100' +
                          (errors.interfaceName && touched.interfaceName ? ' is-invalid' : '')
                        }
                        type="text"
                        id="interfaceName"
                        name="interfaceName"
                        autoComplete='off' />
                      <ErrorMessage
                        name="interfaceName"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <div><label className="labelName">Link</label></div>
                      <Field
                        className={
                          'form-control w-100' +
                          (errors.interfaceLink && touched.interfaceLink ? ' is-invalid' : '')
                        }
                        type="text"
                        id="interfaceLink"
                        name="interfaceLink"
                        autoComplete='off' />
                      <ErrorMessage
                        name="interfaceLink"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <div><label className="labelName">Menu Location</label></div>
                      <Field as="select" name="menuLocation" className="form-control">
                        <option value="">Select Menu Location</option>
                        {
                          menuLocations.map((menuLocation: any) => (<option key={menuLocation} value={menuLocation}>{menuLocation}</option>))
                        }
                      </Field>

                      {/* <select className='form-control' id="menuLocation" name="menuLocation" onChange={meunLocationChangeHandler}>
                        <option value="">Select Menu Location</option>
                        {
                          menuLocations.map((menuLocation: any) => (<option key={menuLocation} value={menuLocation}>{menuLocation}</option>))
                        }
                      </select> */}
                    </div>
                    <div className="col-md-6 mb-3">
                      <div><label className="labelName">Parent Menu</label></div>
                      <Field as="select" name="parentLink" className="form-control">
                        <option value="">Select Parent Menu</option>
                        {
                          interfaces && interfaces.length > 0 && interfaces.map((row: any, index: any) => row.interfaceLink === '#' && (<option key={row.id} value={row.interfaceType}>{row.interfaceType}</option>))
                        }
                      </Field>
                      {/* <Field
                        className={
                          'form-control w-100' +
                          (errors.parentLink && touched.parentLink ? ' is-invalid' : '')
                        }
                        type="text"
                        id="parentLink"
                        name="parentLink"
                        autoComplete='off' />
                      <ErrorMessage
                        name="parentLink"
                        component="div"
                        className="invalid-feedback"
                      /> */}
                    </div>
                    <div className="clearfix"></div>
                    <div className="col-md-6"></div>
                    <div className="col-md-6">
                      <div className="float-right mt-4">
                        <button

                          type='submit'
                          id='sign_in_submit'
                          className='btn btn-sm btn-info'
                        >
                          {!loading && <span className='indicator-label'>Save</span>}
                          {loading && (
                            <span className='indicator-progress' style={{ display: 'block' }}>
                              Please wait...
                              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                            </span>
                          )}
                        </button>
                        <button className='btn btn-sm btn-danger ml-4' type='button' onClick={cancelRequest}>Cancel</button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            <form
              onSubmit={formik.handleSubmit}
              noValidate
              id='login_form'
            >
              {formik.status ? (
                <div className='mb-lg-15 alert alert-danger'>
                  <div className='alert-text font-weight-bold'>{formik.status}</div>
                </div>
              ) : (
                <>
                </>
              )}

            </form>
          </div>)}
        </div>
      </div>
      { !interfaceForm && (
        <div className='mt-5' data-aos="fade-up" data-aos-easing="ease-out-cubic" data-aos-duration="1000" style={{ margin: '20px' }}>
          <table>
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Id</th>
                <th>Name</th>
                <th>Link</th>
                <th>Parent Menu</th>
                <th>Menu Location</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
      )}

    </div>
  )
}

export default UserInterfaces
