import React, { useEffect } from 'react'
import { useUpdateHeaderContext } from '../../../../contexts/HeaderContext'

function GeneratePsid() {
  const updateHeaderContext = useUpdateHeaderContext()

  useEffect(() => {
    updateHeaderContext({ pageTitle: 'Generate Psid'});
  }, [])


  return (
    <div className="dashboard-middle-content">
      <div className="card mb-5 p-3">
        <h6>Filters</h6>
      </div>
    </div>
  )
}

export default GeneratePsid