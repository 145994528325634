import { useState, useEffect } from "react";
import { useUpdateHeaderContext } from "../../../../contexts/HeaderContext";
import { getCompaniesWithServices } from "../../../../services/PsidService";
import { paymentIntimationConfiguration } from "../../../../services/ActiveIntimationPayment";
function ActivateIntimation() {
  const updateHeaderContext = useUpdateHeaderContext();

  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInput, setSearchInput] = useState("");

  const getReportData = async () => {
    const data = await getCompaniesWithServices();
    setServices(data?.data?.content);
  };

  const [visibleServices, setVisibleServices] = useState<{
    [key: string]: boolean;
  }>({});

  const toggleServiceVisibility = (companyId: string) => {
    setVisibleServices((prevState) => ({
      ...prevState,
      [companyId]: !prevState[companyId],
    }));
  };

  const activateIntimation = (serviceId: number, isActive: boolean) => {
    setLoading(true);
    paymentIntimationConfiguration({ serviceId, isActive }).then(() =>
      getReportData()
    );
    setLoading(false);
  };

  useEffect(() => {
    const newVisibleServices = services.reduce((acc: any, company: any) => {
      const isVisible = company.services.some((service: any) =>
        service.title.toLowerCase().includes(searchInput.toLowerCase())
      );
      if (isVisible) {
        acc[company.id] = true;
      }
      return acc;
    }, {});
    setVisibleServices((prevState) => ({
      ...prevState,
      ...newVisibleServices,
    }));
  }, [searchInput, services]);

  useEffect(() => {
    getReportData();
  }, []);

  useEffect(() => {
    updateHeaderContext({ pageTitle: "Activate Intimation" });
  }, []);

  return (
    <div className="dashboard-middle-content">
      <div className="search-input-container mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search for a company or service"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
      </div>
      <div className="card mb-2 p-3">
        <div className="row">
          <h6 className="col-md-3">Intimation Activation</h6>
        </div>
        <div className="row">
          <div className="col-md-6">
            We can enable and disable Intimation for the services given below
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        {services?.map((company: any) => {
          const filteredServices = company?.services?.filter((service: any) =>
            service?.title
              ?.toLocaleLowerCase()
              ?.startsWith(searchInput?.toLocaleLowerCase())
          );
          if (filteredServices.length > 0) {
            return (
              <div style={{ width: "100%" }}>
                <h6
                  className="mt-4"
                  onClick={() => toggleServiceVisibility(company.id.toString())}
                  style={{ cursor: "pointer" }}
                >
                  {company?.title}{" "}
                  {visibleServices[company.id] ? (
                    <i className="fa fa-angle-up" aria-hidden="true"></i>
                  ) : (
                    <i className="fa fa-angle-down" aria-hidden="true"></i>
                  )}
                </h6>
                {visibleServices[company.id] && (
                  <div className="row" style={{ width: "97%", margin: "auto" }}>
                    <div style={{ width: "100%" }}>
                      {company?.services?.map((service: any) => {
                        if (
                          service?.title
                            ?.toLocaleLowerCase()
                            ?.startsWith(searchInput?.toLocaleLowerCase())
                        ) {
                          return (
                            <div
                              key={service?.id}
                              className="d-flex justify-content-between"
                              style={{
                                width: "100%",
                                borderBottom: "1px solid black",
                                margin: "2px",
                              }}
                            >
                              <div>{service?.title}</div>
                              <div>
                                <div className="switch_box">
                                  <input
                                    type="checkbox"
                                    className="switch_1"
                                    checked={service?.isIntimationAllowed}
                                    onChange={() => {
                                      if (!loading) {
                                        activateIntimation(
                                          service.id,
                                          !service.isIntimationAllowed
                                        );
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
}

export default ActivateIntimation;
