import React, { FC, useState, createContext, useContext } from 'react'
import { WithChildren } from '../helpers/AppHelpers';

const HeaderContext = createContext<any>({pageTitle: 'Page Title'});
const UpdateHeaderContext = createContext<any>(null);

const useHeaderContext = () => {
    return useContext(HeaderContext)
}

const useUpdateHeaderContext = () => {
    return useContext(UpdateHeaderContext);
}

const HeaderProvider: FC<WithChildren> = ({children}) => {
    const [headerContext, setHeaderContext] = useState<any>({pageTitle: 'Page Title'});

    const updateHeaderContext = (context: any) => {
        setHeaderContext(context)
    }
    
    return (
        <HeaderContext.Provider value={headerContext}>
            <UpdateHeaderContext.Provider value={updateHeaderContext}>
                {children}
            </UpdateHeaderContext.Provider>
        </HeaderContext.Provider>
    )
} 

export {HeaderProvider, HeaderContext, useHeaderContext, useUpdateHeaderContext}