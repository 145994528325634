import { ICompany } from "../models/interfaces";
import Select from "react-select";

interface iProps {
  companies: ICompany[];
  setCurrentPage?: (value: React.SetStateAction<number>) => void;
  setFilter: any;
  selectedValue?: string;
}

const SelectSearchDropDown = ({
  companies,
  setFilter,
  setCurrentPage,
  selectedValue,
}: iProps) => {
  const getCompanyOptions = () => {
    const mappedCompanies = companies.map((company) => ({
      label: company.getCompanyName(),
      options: company.getServices().map((service) => ({
        value: service.getId(),
        label: service.getServiceName(),
      })),
    }));

    return [
      {
        label: "",
        options: [
          {
            value: "0",
            label: "All",
          },
        ],
      },
      ...mappedCompanies,
    ];
  };

  return (
    <Select
      options={getCompanyOptions()}
      onChange={(selectedOption: any) => {
        const companyServiceId = selectedOption ? selectedOption.value : "0";
        setCurrentPage && setCurrentPage(1);
        setFilter(companyServiceId);
      }}
      value={getCompanyOptions()
        .flatMap((group: any) => group.options)
        .find((option) => option.value === selectedValue)}
      id="serviceDropdown"
      placeholder="Select Service"
      isSearchable={true}
    />
  );
};

export default SelectSearchDropDown;
